import { RAF } from 'lol/js/dom/raf';
export default class InfiniteSlider {
    constructor(opts) {
        this.slides = [];
        this.loopCounter = 0;
        this.maxValue = 0;
        this.opts = opts;
        this.setStates(this.opts.container);
        this.setSlides(this.opts.slides);
        this.init();
    }
    /////////////
    // Init
    /////////////
    init() {
        this.setDefaultTranslate(true);
    }
    setStates($container) {
        this.states = {
            containerWidth: $container.offsetWidth,
            wWidth: window.innerWidth,
            translateValue: 0,
            currentTranslateValue: 0,
            defaultX: 0,
            dragStart: false,
            startX: 0,
            oldX: 0,
        };
    }
    setSlides($slides) {
        let index = 0;
        // reset slides
        this.slides = [];
        // Loop on slides
        while (index < $slides.length) {
            // Get slide
            const $slide = $slides[index];
            // Get metrics
            const { left, right, width } = $slide.getBoundingClientRect();
            // Get visible state
            const visible = left < this.states.wWidth ? true : false;
            // Create Slide
            const slide = {
                el: $slide,
                index,
                left,
                right,
                width,
                visible
            };
            // Store Slide
            this.slides.push(slide);
            // Increment
            index++;
        }
    }
    /////////////
    // Binding
    /////////////
    bindEvents() {
        const passiveSupport = this.getPassiveSupport();
        const $scroller = this.opts.scroller;
        this.onDragStart = this.onDragStart.bind(this);
        this.onDrag = this.onDrag.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.update = this.update.bind(this);
        $scroller.addEventListener("touchstart", this.onDragStart, passiveSupport ? { passive: true } : false);
        $scroller.addEventListener("touchmove", this.onDrag, passiveSupport ? { passive: true } : false);
        $scroller.addEventListener("touchend", this.onDragEnd, passiveSupport ? { passive: true } : false);
        $scroller.addEventListener("touchleave", this.onDragEnd, passiveSupport ? { passive: true } : false);
        this.update(true);
        RAF.subscribe('infiniteSlider', this.update);
    }
    unbindEvents() {
        const $scroller = this.opts.scroller;
        $scroller.removeEventListener("touchstart", this.onDragStart);
        $scroller.removeEventListener("touchmove", this.onDrag);
        $scroller.removeEventListener("touchend", this.onDragEnd);
        $scroller.removeEventListener("touchleave", this.onDragEnd);
        RAF.unsubscribe('infiniteSlider');
    }
    getPassiveSupport() {
        let passiveSupported = false;
        try {
            const options = Object.defineProperty({}, "passive", {
                get: function () {
                    passiveSupported = true;
                }
            });
            window.addEventListener("test", options, options);
            window.removeEventListener("test", options, options);
        }
        catch (err) {
            passiveSupported = false;
        }
        return passiveSupported;
    }
    /////////////
    // Drag methods
    /////////////
    onDragStart(event) {
        this.states.dragStart = true;
        this.states.pointerEvent = event.touches[0] || event.changedTouches[0];
        this.states.oldX = this.states.translateValue;
        this.states.startX = this.states.pointerEvent.clientX;
        // Add drag class
        document.documentElement.classList.add('is-dragging');
    }
    onDrag(event) {
        if (!this.states.dragStart)
            return;
        this.states.pointerEvent = event.touches[0] || event.changedTouches[0];
        this.states.translateValue = this.states.oldX + (this.states.pointerEvent.clientX - this.states.startX);
    }
    onDragEnd() {
        if (!this.states.dragStart)
            return;
        this.states.dragStart = false;
        // Get closest index
        const closestItemIndex = this.getClosest(this.states.translateValue);
        // Send closest index
        this.opts.activeCallback(closestItemIndex);
        // Remove drag class
        document.documentElement.classList.remove('is-dragging');
    }
    /////////////
    // Translate methods
    /////////////
    setDefaultTranslate(isFirstTime = false) {
        const $firstSlide = this.slides[0];
        const slideMargin = $firstSlide.left;
        const slideWidth = $firstSlide.width;
        const wWidth = this.states.wWidth;
        this.states.defaultX = wWidth / 2 - slideWidth / 2 - slideMargin;
        if (isFirstTime) {
            this.states.oldX = this.states.defaultX;
            this.update(true);
        }
    }
    getTranslateValue(isFirst = false) {
        // Center to screen
        const computeTranslateValue = this.states.translateValue + this.states.defaultX;
        const ease = isFirst ? 1 : this.opts.ease;
        this.states.currentTranslateValue += (computeTranslateValue - this.states.currentTranslateValue) * ease;
        // MODULO BUG : https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
        const moduloValue = ((this.states.currentTranslateValue % -this.states.containerWidth) - this.states.containerWidth) % -this.states.containerWidth;
        return moduloValue;
    }
    getClosest(value) {
        // Set index
        let index = 0;
        // Set numbers array
        const offsets = [];
        // Slides metrics
        const $firstSlide = this.slides[0];
        const slideMargin = $firstSlide.left;
        const slideWidth = $firstSlide.width;
        const slideTotal = $firstSlide.right;
        if (value < (this.states.containerWidth * this.loopCounter)) {
            this.loopCounter--;
        }
        else if (value > this.states.containerWidth * (this.loopCounter + 1) - slideTotal / 2) {
            this.loopCounter++;
        }
        while (index < this.slides.length) {
            const slide = this.slides[index];
            // Default
            if (this.loopCounter === 0) {
                const offset = slide.left - slideMargin;
                offsets.push(offset);
                // To the right
            }
            else if (this.loopCounter < 0) {
                const itemMetrics = (slideWidth * (index + 1) + (slideMargin * (index + 1)));
                const offset = (itemMetrics * -1) + (slideTotal * this.slides.length * (this.loopCounter + 1));
                offsets.push(offset);
                // To the left
            }
            else if (this.loopCounter > 0) {
                const itemMetrics = (slideWidth * (index) + (slideMargin * (index)));
                const offset = itemMetrics + (slideTotal * this.slides.length * (this.loopCounter));
                offsets.push(offset);
            }
            index++;
        }
        // Find closest offset
        const closest = offsets.reduce(function (prev, curr) {
            return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        });
        // Set max value
        this.maxValue = offsets[offsets.length - 1];
        // Apply transform
        this.states.oldX = this.states.translateValue = closest;
        ///////////////////
        // Get Slide Index
        //////////////////
        let slideIndex;
        const offsetIndex = offsets.indexOf(closest);
        if (this.loopCounter < 0) {
            slideIndex = offsetIndex + 1;
        }
        else if (this.loopCounter === 0) {
            slideIndex = offsets.length - offsetIndex;
        }
        else if (this.loopCounter > 0) {
            slideIndex = offsetIndex;
        }
        if (slideIndex > offsets.length - 1) {
            slideIndex = 0;
        }
        return slideIndex;
    }
    /////////////
    // Update
    /////////////
    update(isFirst = false) {
        const translateValue = this.getTranslateValue(isFirst);
        this.opts.scroller.style.transform = `translate3d(${translateValue}px, 0, 0)`;
    }
}
