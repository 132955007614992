import lottie from 'lottie-web';
import tridentAnimationData from 'assets/bodymovin/bodymovin_trident.json';
import arrowsAnimationData from 'assets/bodymovin/bodymovin_arrows.json';
import anime from 'animejs';
import UIIcon from "components/ui-icon/ui-icon.vue";
import DragSlider from "utils/DragSlider";
import { seconds } from "lol/js/promise/time";
import { SectionMixin } from "mixins/SectionMixin";
import { getGlApp } from "mixins/GLAppProvider";
export default {
  mixins: [SectionMixin],

  data() {
    return {
      donations: [{
        data: 5,
        label: "5$"
      }, {
        data: 10,
        label: "10$"
      }, {
        data: 20,
        label: "20$"
      }, {
        data: 50,
        label: "50$"
      }, {
        data: 100,
        label: "100$"
      }, {
        data: 500,
        label: "500$"
      }, {
        data: 1000,
        label: "more"
      }],
      stats: [],
      progressIndex: -1,
      roundProgressIndex: -1,
      activeIndex: 0,
      sliderActive: false,
      sliderGrab: false,
      sliderBackGrab: false,
      isPanelActive: false,
      isPanelHidden: true
    };
  },

  components: {
    "ui-icon": UIIcon
  },

  created() {
    this.getDatas();
  },

  mounted() {
    // Init Drag Slider
    this.dragSlider = new DragSlider({
      container: this.$refs.dragContainer,
      indicator: this.$refs.indicator,
      indicatorPress: this.$refs.indicatorPress,
      line: this.$refs.line,
      items: this.$refs.sliderItems,
      setProgressIndex: this.setProgressIndex,
      setRoundProgressIndex: this.setRoundProgressIndex,
      setActiveIndex: this.setActiveIndex,
      setProgressValue: this.setProgressValue,
      setGrabState: this.setGrabState,
      indicatorEase: .1,
      indicatorPressEase: .09
    }); // Load trident Animation

    this.tridentAnimation = lottie.loadAnimation({
      animationData: tridentAnimationData,
      container: this.$refs.tridentContainer,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    }); // Load arrows Animation

    this.arrowsAnimation = lottie.loadAnimation({
      animationData: arrowsAnimationData,
      container: this.$refs.arrowsContainer,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    });
  },

  methods: {
    /////////////
    // Components methods
    /////////////
    enterComponent() {
      this.activeDragSlider();
      this.bindEvents();
      this.enterAnimation();
      const glapp = getGlApp();
      glapp.glview.onFrame.on(this.onGLFrame);
    },

    leaveComponent() {
      this.unactiveDragSlider();
      this.unbindEvents();
      this.leaveAnimation();
      const glapp = getGlApp();
      glapp.glview.onFrame.off(this.onGLFrame);
    },

    async enterAnimation() {
      setTimeout(() => {
        this.sliderActive = true;
        this.$refs.backBtn.classList.add('is-appear');
        this.$refs.card.classList.add('is-appear');
      }, 2000);
      setTimeout(() => {
        this.arrowsAnimation.goToAndPlay(0);
      }, 2500);
      setTimeout(() => {
        this.$refs.donateBtn.classList.add('is-appear');
      }, 3100);
    },

    async leaveAnimation() {
      this.sliderActive = false;
      this.sliderBackGrab = false;
      this.$refs.donateBtn.classList.remove('is-appear');
      this.$refs.backBtn.classList.remove('is-appear');
      this.$refs.card.classList.remove('is-appear');
    },

    /////////////
    // Events methods
    /////////////
    bindEvents() {
      this.unwatchWidth = this.$watch('$appdatas.store.screen.width', function () {
        this.onResize();
      });
      this.unwatchHeight = this.$watch('$appdatas.store.screen.height', function () {
        this.onResize();
      });
    },

    unbindEvents() {
      // Unwatch
      // https://codingexplained.com/coding/front-end/vue-js/adding-removing-watchers-dynamically
      this.unwatchWidth();
      this.unwatchHeight();
    },

    onResize() {
      this.dragSlider && this.dragSlider.resize();
    },

    /////////////
    // Get datas
    /////////////
    getDatas() {
      const datas = this.t('datas');
      const stats = datas['donation-stats'];
      this.stats = Object.values(stats);
    },

    /////////////
    // Boat Card Methods
    /////////////
    onGLFrame() {
      const cardPos = this.$appdatas.store.boatCardPos;
      if (!this.$refs.boatCardPlacer) return;
      this.$refs.boatCardPlacer.style = `transform: translate3D(${cardPos[0]}px,${cardPos[1]}px,0)`;
    },

    /////////////
    // Navigation methods
    /////////////
    goBack() {
      // this.$appdatas.audioLayout.play('CLICK', 1);
      this.$emit('nextView');
    },

    /////////////
    // Donation methods
    /////////////
    activeDragSlider() {
      this.dragSlider && this.dragSlider.bindEvents();
      this.onResize();
    },

    unactiveDragSlider() {
      this.dragSlider && this.dragSlider.unbindEvents();
    },

    setDonationIndex(index) {
      this.dragSlider && this.dragSlider.goTo(index);
    },

    setActiveIndex(index) {
      this.activeIndex = index;
      this.$appdatas.store.donationDragProgressIndex = 1 / (this.donations.length - 1) * index;
    },

    setProgressIndex(index) {
      this.progressIndex = index;
    },

    setProgressValue(value) {
      this.$appdatas.store.donationDragProgressValue = value;
    },

    setRoundProgressIndex(index) {
      this.roundProgressIndex = index;
    },

    setGrabState(bool) {
      // If Grab
      if (bool) {
        this.sliderGrab = true;
        this.sliderBackGrab = false;
        this.tridentAnimation.goToAndPlay(0); // Else
      } else {
        this.sliderGrab = false;
        this.sliderBackGrab = true;
        setTimeout(() => {
          this.arrowsAnimation.goToAndPlay(0);
        }, 400);
      }
    },

    /////////////
    // Donation panel
    /////////////
    async onPanelOpen() {
      this.isPanelHidden = false;
      await seconds(0.1);
      this.isPanelActive = true;
      await seconds(0.8);
      this.$refs.closePanelBtn.classList.add('is-appear');
      await seconds(0.1);
      this.$refs.donateLinkOdd.classList.add('is-appear');
      this.$refs.donateLinkMobileOdd.classList.add('is-appear');
      await seconds(0.1);
      this.$refs.donateLinkEven.classList.add('is-appear');
      this.$refs.donateLinkMobileEven.classList.add('is-appear');
    },

    async onPanelClose() {
      this.$refs.closePanelBtn.classList.remove('is-appear');
      await seconds(0.1);
      this.isPanelActive = false;
      await seconds(1);
      this.isPanelHidden = true;
      this.$refs.donateLinkOdd.classList.remove('is-appear');
      this.$refs.donateLinkMobileOdd.classList.remove('is-appear');
      this.$refs.donateLinkEven.classList.remove('is-appear');
      this.$refs.donateLinkMobileEven.classList.remove('is-appear');
    }

  }
};