var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      class: {
        "is-desktop": !_vm.$appdatas.store.isTouchable,
        "is-mobile": _vm.$appdatas.store.isTouchable
      }
    },
    [
      _c("GLViewer", { ref: "viewer", attrs: { assetsUrl: "/assets/webgl" } }),
      _vm._v(" "),
      _c("Layout")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }