import { RAF } from 'lol/js/dom/raf';
import lottie from 'lottie-web';
import tridentAnimationData from 'assets/bodymovin/bodymovin_trident_white.json';
import UIIcon from "components/ui-icon/ui-icon.vue";
import Ship from "components/ship/ship.vue";
import InfiniteScroller from "utils/InfiniteScroller";
import InfiniteSlider from "utils/InfiniteSlider";
import { seconds } from "lol/js/promise/time";
import { SectionMixin } from "mixins/SectionMixin";
import { getGlApp } from "mixins/GLAppProvider";
import ObjectFit from "components/object-fit/object-fit.vue";
const NUMBERS_CAROUSEL_TIME = 4000;
const SHIPS_LIST_TIME_BEFORE_CLOSE = 1000;
const SHIPS_ITEM_TIME_HOVER = 100;
const SHIPS_ITEM_INTERACTIVE = 500;
export default {
  mixins: [SectionMixin],
  props: {
    covers: Object,
    thumbnails: Object
  },

  data() {
    return {
      ships: [],
      shipsListActive: false,
      cursorActive: false,
      shipsInteractive: false,
      numbers: [],
      activeNumberIndex: 0,
      shipViewOpen: false,
      donateBtnAppear: false
    };
  },

  components: {
    "ui-icon": UIIcon,
    "c-ship": Ship,
    ObjectFit
  },

  created() {
    this.getShips();
    this.getNumbers();
  },

  mounted() {
    if (!this.$appdatas.store.isTouchable) {
      // Create infinite scroller instance
      this.infiniteScroller = new InfiniteScroller({
        scroller: this.$refs.scroller,
        container: this.$refs.slider,
        slides: this.$refs.slide,
        ease: 0.09,
        friction: .3
      }); // Set animated list

      this.setAnimatedList(); // Load trident Animation

      this.tridentAnimation = lottie.loadAnimation({
        animationData: tridentAnimationData,
        container: this.$refs.shipsListCursor,
        renderer: 'canvas',
        rendererSettings: {
          clearCanvas: true,
          preserveAspectRatio: 'none'
        },
        loop: false,
        autoplay: false
      });
    } else {
      // Create infinite slider instance
      this.infiniteSlider = new InfiniteSlider({
        scroller: this.$refs.scroller,
        container: this.$refs.slider,
        slides: this.$refs.slide,
        ease: .09,
        activeCallback: this.setActiveShip
      });
    }

    this.locateShips();
  },

  beforeDestroy() {
    this.stopNumbers();
  },

  methods: {
    /////////////
    // Components methods
    /////////////
    enterComponent() {
      const glapp = getGlApp();
      glapp.glview.onFrame.on(this.onGLFrame);
      this.bindEvents();
      this.enterAnimation();
    },

    leaveComponent() {
      this.stopNumbers();
      const glapp = getGlApp();
      glapp.glview.onFrame.off(this.onGLFrame);
      this.unbindEvents();
      this.leaveAnimation();
      this.hideCursor();
      this.hideHotspots();
    },

    async enterAnimation() {
      await seconds(this.$appdatas.store.fadeViewDuration);
      this.$refs.donateBtn.classList.add('is-appear');
      this.$refs.fleetBtn.classList.add('is-appear');
      this.$refs.numbersDatas.classList.add('is-appear');
      if (this.$appdatas.store.isTouchable) this.$refs.mobileAction.classList.add('is-appear');
      await seconds(1);
      this.$refs.numbersDatas.classList.add('is-animated');
      this.playNumbers();
    },

    async leaveAnimation() {
      this.$refs.donateBtn.classList.remove('is-appear');
      this.$refs.fleetBtn.classList.remove('is-appear');
      this.$refs.numbersDatas.classList.remove('is-appear');
      this.$refs.numbersDatas.classList.remove('is-animated');
      if (this.$appdatas.store.isTouchable) this.$refs.mobileAction.classList.remove('is-appear');
    },

    /////////////
    // Get datas
    /////////////
    getShips() {
      const datas = this.t('datas');
      const ships = datas.ships;
      this.ships = Object.values(ships);
      this.ships.map(ship => {
        ship.fullName = `${ship.firstName}<br>${ship.lastName}`;
      });
    },

    getNumbers() {
      const datas = this.t('datas');
      const numbers = datas.numbers;
      this.numbers = Object.values(numbers);
    },

    /////////////
    // Events methods
    /////////////
    bindEvents() {
      this.unwatchWidth = this.$watch('$appdatas.store.screen.width', function () {
        this.onResize();
      });
      this.unwatchHeight = this.$watch('$appdatas.store.screen.height', function () {
        this.onResize();
      });
      window.addEventListener('click', this.onGlobeClick);
    },

    unbindEvents() {
      // Unwatch
      // https://codingexplained.com/coding/front-end/vue-js/adding-removing-watchers-dynamically
      this.unwatchWidth();
      this.unwatchHeight();
      window.removeEventListener('click', this.onGlobeClick);
    },

    onResize() {
      this.infiniteScroller && this.infiniteScroller.resize();
    },

    onGlobeClick() {
      const idx = this.$appdatas.store.glActiveShipIdx;

      if (idx > -1 && !this.shipsListActive && this.$appdatas.store.isGlobeInteractive) {
        this.showShipView(idx);
      }
    },

    /////////////
    // Numbers methods
    /////////////
    playNumbers() {
      let index = 0;
      this.interval = setInterval(() => {
        index++;
        this.activeNumberIndex = index % this.numbers.length;
      }, NUMBERS_CAROUSEL_TIME);
    },

    stopNumbers() {
      this.interval && clearInterval(this.interval);
    },

    /////////////
    // Ships List methods
    /////////////
    activeShipsScroller() {
      this.infiniteScroller && this.infiniteScroller.bindEvents();
      this.showCards();
      this.$appdatas.store.popinOpen = true;
      this.$appdatas.store.cardsOpen = true;
    },

    unactiveShipsScroller() {
      this.infiniteScroller && this.infiniteScroller.unbindEvents();
      this.hideCards();
      this.$appdatas.store.popinOpen = false;
      this.$appdatas.store.cardsOpen = false;
    },

    activeShipsSlider() {
      this.infiniteSlider && this.infiniteSlider.bindEvents();
      this.$appdatas.store.popinOpen = true;
    },

    unactiveShipsSlider() {
      this.infiniteSlider && this.infiniteSlider.unbindEvents();
      this.$appdatas.store.popinOpen = false;
    },

    showShipView(index) {
      this.$refs.shipViews && this.$refs.shipViews[index] && this.$refs.shipViews[index].open();
      this.$appdatas.store.openedShip = this.$appdatas.store.currentShip;
      this.shipViewOpen = true;
    },

    hideShipView(forced) {
      this.shipViewOpen = false;
      this.shipListTimeout && clearTimeout(this.shipListTimeout);

      if (forced) {
        this.onShipListLeave();
      }
    },

    onBrowseFleet() {
      this.shipsListActive = true;

      if (!this.$appdatas.store.isTouchable) {
        this.activeShipsScroller();
        setTimeout(() => {
          this.shipsInteractive = true;
          this.setActiveShip(0);
        }, SHIPS_ITEM_INTERACTIVE);
      } else {
        this.activeShipsSlider();
      }
    },

    onCloseFleet() {
      this.shipsListActive = false;
      this.unactiveShipsSlider();
    },

    onShipListEnter() {
      if (this.$appdatas.store.isTouchable) return;
      this.shipListTimeout && clearTimeout(this.shipListTimeout);
      this.showCursor();
    },

    onShipListLeave() {
      if (this.$appdatas.store.isTouchable || this.shipViewOpen) return;
      this.shipListTimeout = setTimeout(() => {
        this.shipsListActive = false;
        this.shipsInteractive = false;
        this.$nextTick(() => {
          this.unactiveShipsScroller();
          this.onShipItemLeave();
        });
        this.$appdatas.store.currentShip = -1;
        this.$appdatas.store.currentShipIdx = -1;
      }, SHIPS_LIST_TIME_BEFORE_CLOSE);
      this.hideCursor();
    },

    hideHotspots() {
      this.$refs.hotspots.map((hotspot, idx) => {
        hotspot.classList.remove('visible');
      });
    },

    onShipListMove(e) {
      this.moveCursor(e);
    },

    onShipItemLeave() {
      if (this.$appdatas.store.isTouchable || !this.shipsInteractive) return;
      this.shipItemTimeout && clearTimeout(this.shipItemTimeout);
    },

    onShipItemEnter(index) {
      if (this.$appdatas.store.isTouchable || !this.shipsInteractive) return;
      this.shipItemTimeout = setTimeout(() => {
        this.setActiveShip(index);
      }, SHIPS_ITEM_TIME_HOVER);
    },

    setActiveShip(index) {
      this.$appdatas.store.currentShip = this.ships[index].slug;
      this.$appdatas.store.currentShipIdx = index;
    },

    goDonate() {
      // this.$appdatas.audioLayout.play('CLICK', 1);
      this.$emit('nextView');
    },

    setAnimatedList() {
      let index = 0;
      this.slidesArray = [];
      const $slides = this.$refs.slide;
      const $duplicatedSlides = [...this.$refs.duplicateSlide];
      const $firstItem = $duplicatedSlides.shift(); // Push first item

      this.slidesArray.push([$firstItem]); // Reverse slides array

      $slides.reverse();

      while (index < $slides.length) {
        const array = [];
        array.push($slides[index]);

        if (index < $duplicatedSlides.length) {
          array.push($duplicatedSlides[index]);
        }

        this.slidesArray.push(array);
        index++;
      }
    },

    showCards() {
      let index = 0;

      while (index < this.slidesArray.length) {
        const currentSlideArray = this.slidesArray[index];
        let arrayIndex = 0;

        while (arrayIndex < currentSlideArray.length) {
          const $card = currentSlideArray[arrayIndex];
          const $slide = $card.querySelector('.c-ShipsCard__slide');
          setTimeout(() => {
            $slide.classList.add('is-appear');
          }, 166 * index);
          arrayIndex++;
        }

        index++;
      }
    },

    hideCards() {
      const items = [...this.$refs.slide, ...this.$refs.duplicateSlide];
      let index = 0;

      while (index < items.length) {
        const $card = items[index];
        const $slide = $card.querySelector('.c-ShipsCard__slide');
        $slide.classList.remove('is-appear');
        index++;
      }
    },

    /////////////
    // on GL frame
    /////////////
    onGLFrame(dt) {
      const xOffset = this.$appdatas.store.isTouchable ? 10 : 20;
      const yOffset = this.$appdatas.store.isTouchable ? -15 : -30;
      let minScale = .6;
      let maxScale = .9;

      if (this.$appdatas.store.isTouchable) {
        if (this.$appdatas.store.screen.width > 640) {
          minScale = .5;
          maxScale = .7;
        } else {
          minScale = .2;
          maxScale = .4;
        }
      }

      this.$refs.hotspots.map((hotspot, idx) => {
        const data = this.$appdatas.store.hotspots[idx];
        if (!data) return;
        const x = data.screenPos[0];
        const y = data.screenPos[1];
        const s = minScale + maxScale * data.dist;
        hotspot.style.transform = `translate3d(${x}px, ${y}px, 0) scale(${s}, ${s})`; // visible ?

        if (data.visible && data.opacity == 1) hotspot.classList.add('visible');else hotspot.classList.remove('visible'); // overed ?

        if (data.overed > 0 && !this.shipsListActive) hotspot.classList.add('is-active');else hotspot.classList.remove('is-active'); // faded

        if (!this.$appdatas.store.isTouchable) {
          if (data.faded > 0) hotspot.classList.add('faded');else hotspot.classList.remove('faded');
        } // scale
        // this.$refs.hotspotlist.style.fontSize = `${s}em`

      });
    },

    // Ships Locations methods
    /////////////
    locateShips(_x, _y) {// let shipsEl = document.querySelectorAll(".c-Hotspots__list__item")
      // shipsEl.forEach(el => {
      // let x = _x||Math.random()*window.innerWidth
      // let y = _y||Math.random()*window.innerHeight
      // el.style = `transform : translate3D(${x}px,${y}px,0);`
      // });
    },

    /////////////
    // About panel
    ////////////
    openAbout() {
      this.$emit('onOpenAbout');
    },

    /////////////
    // Custom ship list cursor
    ////////////
    async showCursor() {
      if (this.$appdatas.store.isTouchable) return;

      if (!this.currentCursorX) {
        this.originCursorX = this.currentCursorX = 0;
      } else {
        this.originCursorX = this.currentCursorX;
      }

      if (!this.currentCursorY) {
        this.originCursorY = this.currentCursorY = 0;
      } else {
        this.originCursorY = this.currentCursorY;
      }

      if (this.cursorActive) return;
      this.cursorActive = true;
      RAF.subscribe('cursorList', this.updateCursor);
      await seconds(.5);
      this.tridentAnimation.goToAndPlay(0);
    },

    hideCursor() {
      this.cursorActive = false;
      if (this.$appdatas.store.isTouchable) return;
      RAF.unsubscribe('cursorList');
      this.tridentAnimation.goToAndStop(0);
    },

    moveCursor(e) {
      if (this.$appdatas.store.isTouchable) return;
      this.originCursorX = e.clientX - this.$appdatas.store.screen.width;
      this.originCursorY = e.clientY;
    },

    computeCursorCoords() {
      this.currentCursorX += this.originCursorX - this.currentCursorX;
      this.currentCursorY += this.originCursorY - this.currentCursorY;
      return {
        x: this.currentCursorX,
        y: this.currentCursorY
      };
    },

    updateCursor() {
      const {
        x,
        y
      } = this.computeCursorCoords();
      this.$refs.shipsListCursor.style.transform = `translate3d(${x}px,${y}px,0)`;
    }

  }
};