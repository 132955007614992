import { RAF } from 'lol/js/dom/raf';
import { clamp } from "@webgl/math";
import Datas from 'datas';
import datas from 'datas';
export default class IntroDrag {
    constructor(opts) {
        this.opts = opts;
        this.setStates();
    }
    /////////////
    // Binding
    /////////////
    bindEvents() {
        // reset
        this.reset();
        const passiveSupport = this.getPassiveSupport();
        this.onDragStart = this.onDragStart.bind(this);
        this.onDrag = this.onDrag.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.update = this.update.bind(this);
        window.addEventListener("touchstart", this.onDragStart, passiveSupport ? { passive: true } : false);
        window.addEventListener("touchmove", this.onDrag, passiveSupport ? { passive: true } : false);
        window.addEventListener("touchend", this.onDragEnd, passiveSupport ? { passive: true } : false);
        window.addEventListener("touchleave", this.onDragEnd, passiveSupport ? { passive: true } : false);
        window.addEventListener("mousedown", this.onDragStart, passiveSupport ? { passive: true } : false);
        window.addEventListener("mousemove", this.onDrag, passiveSupport ? { passive: true } : false);
        window.addEventListener("mouseup", this.onDragEnd, passiveSupport ? { passive: true } : false);
        window.addEventListener("mouseleave", this.onDragEnd, passiveSupport ? { passive: true } : false);
        RAF.subscribe('dragIntro', this.update);
    }
    unbindEvents() {
        window.removeEventListener("touchstart", this.onDragStart);
        window.removeEventListener("touchmove", this.onDrag);
        window.removeEventListener("touchend", this.onDragEnd);
        window.removeEventListener("touchleave", this.onDragEnd);
        window.removeEventListener("mousedown", this.onDragStart);
        window.removeEventListener("mousemove", this.onDrag);
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("mouseleave", this.onDragEnd);
        RAF.unsubscribe('dragIntro');
    }
    getPassiveSupport() {
        let passiveSupported = false;
        try {
            const options = Object.defineProperty({}, "passive", {
                get: function () {
                    passiveSupported = true;
                }
            });
            window.addEventListener("test", options, options);
            window.removeEventListener("test", options, options);
        }
        catch (err) {
            passiveSupported = false;
        }
        return passiveSupported;
    }
    /////////////
    // Drag methods
    /////////////
    onDragStart(event) {
        if (!datas.store.isIntroSceneFinished)
            return;
        this.states.dragStart = true;
        this.states.pointerEvent = Datas.store.isTouchable ? (event.touches[0] || event.changedTouches[0]) : event;
        this.states.startX = this.states.pointerEvent.clientX;
        // Add drag class
        document.documentElement.classList.add('is-dragging');
        // Start callback
        this.opts.onStart && this.opts.onStart();
    }
    onDrag(event) {
        if (!this.states.dragStart)
            return;
        this.states.pointerEvent = Datas.store.isTouchable ? (event.touches[0] || event.changedTouches[0]) : event;
        this.states.translateValue = clamp(this.states.oldX + (this.states.pointerEvent.clientX - this.states.startX), 0, this.states.maxWidth);
    }
    onDragEnd() {
        if (!this.states.dragStart)
            return;
        this.states.dragStart = false;
        if (this.states.progressValue < this.opts.completeThreshold) {
            this.states.oldX = this.states.translateValue = 0;
        }
        else {
            this.states.oldX = this.states.translateValue = this.states.maxWidth;
        }
        // Remove drag class
        document.documentElement.classList.remove('is-dragging');
        // End callback
        this.opts.onEnd && this.opts.onEnd();
    }
    /////////////
    // Compute datas
    /////////////
    computeTranslateValue() {
        this.states.currentTranslateValue += (this.states.translateValue - this.states.currentTranslateValue) * this.opts.dragEase;
        return this.states.currentTranslateValue;
    }
    getProgressValue() {
        const progress = (this.states.currentTranslateValue / this.states.maxWidth);
        this.opts.onProgressChange && this.opts.onProgressChange(progress);
        return progress;
    }
    updateDragable(progress) {
        const { x, y } = this.computeCoords(progress);
        const tgtProgress = progress + 0.00001;
        const tgtCoords = this.computeCoords(tgtProgress);
        const dx = tgtCoords.x - x;
        const dy = tgtCoords.y - y;
        const angle = Math.atan2(dy, dx) * (180 / Math.PI);
        return { x, y, angle };
    }
    computeCoords(progress) {
        let startAngle = Math.PI - this.opts.dragAngle;
        let endAngle = this.opts.dragAngle;
        let rx = this.opts.container.offsetWidth / 2;
        let ry = this.opts.container.offsetHeight * this.opts.elipseHeightRatio;
        let center = this.opts.container.offsetWidth / 2;
        let currentAngle = startAngle - ((startAngle - endAngle) * progress);
        let x = center + Math.cos(currentAngle) * rx;
        let y = center + Math.sin(currentAngle) * ry;
        return { x, y };
    }
    getEndCoord() {
        let startAngle = Math.PI - this.opts.dragAngle;
        let endAngle = this.opts.dragAngle;
        let rx = this.opts.container.offsetWidth / 2;
        let ry = this.opts.container.offsetHeight * this.opts.elipseHeightRatio;
        let center = this.opts.container.offsetWidth / 2;
        let currentAngle = startAngle - ((startAngle - endAngle) * 1);
        let x = center + Math.cos(currentAngle) * rx;
        let y = center + Math.sin(currentAngle) * ry;
        return { x, y };
    }
    /////////////
    // Set datas
    /////////////
    setStates() {
        this.states = {
            translateValue: 0,
            currentTranslateValue: 0,
            dragStart: false,
            startX: 0,
            oldX: 0,
            progressValue: 0,
            isComplete: false,
            maxWidth: this.opts.container.offsetWidth * 0.78,
            dragElWidth: this.opts.dragElement.offsetWidth,
            dragElHeight: this.opts.dragElement.offsetHeight
        };
        this.opts.container.style.height = this.opts.container.offsetWidth + "px";
    }
    /////////////
    // Resize
    /////////////
    resize() {
        this.opts.container.style.height = this.opts.container.offsetWidth + "px";
        this.states.maxWidth = this.opts.container.offsetWidth * 0.78;
        this.states.dragElWidth = this.opts.dragElement.offsetWidth,
            this.states.dragElHeight = this.opts.dragElement.offsetHeight;
    }
    /////////////
    // Reset
    /////////////
    reset() {
        this.setStates();
    }
    /////////////
    // Update
    /////////////
    update() {
        if (this.states.isComplete)
            return;
        this.computeTranslateValue();
        this.states.progressValue = this.getProgressValue();
        const { x, y, angle } = this.updateDragable(this.states.progressValue);
        this.opts.dragElement.style.transform = `translate3d(${x}px,${y}px,0) rotate(${angle}deg)`;
        this.opts.dragElement.style.top = `${-this.states.dragElWidth / 2}px`;
        this.opts.dragElement.style.left = `${-this.states.dragElHeight / 2}px`;
        this.opts.endPoint.style.transform = `translate3d(${this.getEndCoord().x}px,${this.getEndCoord().y}px,0)`;
        this.opts.endPoint.style.top = `${-this.opts.endPoint.offsetWidth / 2}px`;
        this.opts.endPoint.style.left = `${-this.opts.endPoint.offsetHeight / 2}px`;
        // Emit complete 
        if (this.states.progressValue > .99) {
            this.states.isComplete = true;
            this.opts.onComplete && this.opts.onComplete();
            this.unbindEvents();
        }
    }
}
