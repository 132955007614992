import countryLatLong from "datas/country-lat-long-list.json";
import { Net } from 'lol/js/dom/net';
export function setGeoIP() {
    return new Promise(async (resolve) => {
        const res = await Net.xhr(window.location.href, { method: 'HEAD' });
        const countryCode = res.request.getResponseHeader('Cloudfront-Viewer-Country') || 'FR';
        const countryFilter = countryLatLong[countryCode.toLowerCase()];
        resolve(countryFilter);
    });
}
