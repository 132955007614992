import { RAF } from 'lol/js/dom/raf';
import { clamp } from "@webgl/math";
import Datas from 'datas';
export default class DragSlider {
    constructor(opts) {
        this.opts = opts;
        this.setStates();
        this.setSteps();
    }
    /////////////
    // Binding
    /////////////
    bindEvents() {
        const passiveSupport = this.getPassiveSupport();
        this.onDragStart = this.onDragStart.bind(this);
        this.onDrag = this.onDrag.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.update = this.update.bind(this);
        window.addEventListener("touchstart", this.onDragStart, passiveSupport ? { passive: true } : false);
        window.addEventListener("touchmove", this.onDrag, passiveSupport ? { passive: true } : false);
        window.addEventListener("touchend", this.onDragEnd, passiveSupport ? { passive: true } : false);
        window.addEventListener("touchleave", this.onDragEnd, passiveSupport ? { passive: true } : false);
        window.addEventListener("mousedown", this.onDragStart, passiveSupport ? { passive: true } : false);
        window.addEventListener("mousemove", this.onDrag, passiveSupport ? { passive: true } : false);
        window.addEventListener("mouseup", this.onDragEnd, passiveSupport ? { passive: true } : false);
        window.addEventListener("mouseleave", this.onDragEnd, passiveSupport ? { passive: true } : false);
        RAF.subscribe('dragSlider', this.update);
    }
    unbindEvents() {
        window.removeEventListener("touchstart", this.onDragStart);
        window.removeEventListener("touchmove", this.onDrag);
        window.removeEventListener("touchend", this.onDragEnd);
        window.removeEventListener("touchleave", this.onDragEnd);
        window.removeEventListener("mousedown", this.onDragStart);
        window.removeEventListener("mousemove", this.onDrag);
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("mouseleave", this.onDragEnd);
        RAF.unsubscribe('dragSlider');
        // Reset
        this.reset();
    }
    getPassiveSupport() {
        let passiveSupported = false;
        try {
            const options = Object.defineProperty({}, "passive", {
                get: function () {
                    passiveSupported = true;
                }
            });
            window.addEventListener("test", options, options);
            window.removeEventListener("test", options, options);
        }
        catch (err) {
            passiveSupported = false;
        }
        return passiveSupported;
    }
    /////////////
    // Drag methods
    /////////////
    onDragStart(event) {
        if (event.target != this.opts.indicator)
            return;
        this.states.dragStart = true;
        this.opts.setGrabState(true);
        this.states.pointerEvent = Datas.store.isTouchable ? (event.touches[0] || event.changedTouches[0]) : event;
        this.states.startX = this.states.pointerEvent.clientX;
        // Add drag class
        document.documentElement.classList.add('is-dragging');
    }
    onDrag(event) {
        if (!this.states.dragStart)
            return;
        this.states.pointerEvent = Datas.store.isTouchable ? (event.touches[0] || event.changedTouches[0]) : event;
        this.states.translateValue = clamp(this.states.oldX + (this.states.pointerEvent.clientX - this.states.startX), 0, this.states.maxWidth);
    }
    onDragEnd() {
        if (!this.states.dragStart)
            return;
        this.states.dragStart = false;
        this.opts.setGrabState(false);
        this.getClosest(this.states.translateValue);
        // Remove drag class
        document.documentElement.classList.remove('is-dragging');
    }
    getTranslateValue() {
        this.states.currentTranslateValue += (this.states.translateValue - this.states.currentTranslateValue) * this.opts.indicatorEase;
        return this.states.currentTranslateValue;
    }
    getAlternativeTranslateValue() {
        this.states.alternativeTranslateValue += (this.states.translateValue - this.states.alternativeTranslateValue) * this.opts.indicatorPressEase;
        return this.states.alternativeTranslateValue;
    }
    getProgressValue() {
        const easingProgress = Math.round((this.states.currentTranslateValue / this.states.maxWidth) * 100) / 100;
        const rawProgress = (this.states.translateValue / this.states.maxWidth);
        if (rawProgress != this.states.progressValue) {
            this.opts.setProgressValue(rawProgress);
        }
        this.states.progressValue = easingProgress;
        return easingProgress;
    }
    getProgressIndex(progress) {
        const progressIndex = Math.ceil(progress * (this.states.itemsLength - 1));
        if (this.states.progressIndex === progressIndex)
            return;
        this.states.progressIndex = progressIndex;
        this.opts.setProgressIndex(progressIndex);
    }
    getRoundProgressIndex(progress) {
        const progressIndex = Math.round(progress * (this.states.itemsLength - 1));
        if (this.states.roundProgressIndex === progressIndex)
            return;
        this.states.roundProgressIndex = progressIndex;
        this.opts.setRoundProgressIndex(progressIndex);
    }
    getVelocity() {
        const velocity = (this.states.alternativeTranslateValue - this.states.prevAlternativeTranslateValue);
        this.states.prevAlternativeTranslateValue = this.states.alternativeTranslateValue;
        return Math.floor(velocity);
    }
    getClosest(translateValue) {
        const closest = this.states.steps.reduce(function (prev, curr) {
            return (Math.abs(curr - translateValue) < Math.abs(prev - translateValue) ? curr : prev);
        });
        this.states.oldX = this.states.translateValue = closest;
        const activeIndex = this.states.steps.indexOf(closest);
        this.states.activeStep = activeIndex;
        this.opts.setActiveIndex(activeIndex);
    }
    goTo(index, emit = true) {
        const currentStep = this.states.steps[index];
        this.states.oldX = this.states.translateValue = currentStep;
        this.states.activeStep = index;
        if (emit) {
            this.opts.setActiveIndex(index);
        }
    }
    reset() {
        this.goTo(0, true);
    }
    /////////////
    // Set datas
    /////////////
    setStates() {
        this.states = {
            translateValue: 0,
            currentTranslateValue: 0,
            alternativeTranslateValue: 0,
            prevAlternativeTranslateValue: 0,
            dragStart: false,
            defaultX: 0,
            startX: 0,
            oldX: 0,
            steps: [],
            activeStep: 0,
            itemsLength: this.opts.items.length,
            maxWidth: this.opts.container.offsetWidth,
            progressIndex: -1,
            progressValue: -1,
            roundProgressIndex: -1,
            velocity: -1
        };
    }
    setSteps() {
        this.states.steps = [];
        const increment = this.states.maxWidth / (this.states.itemsLength - 1);
        let index = 0;
        while (index < this.states.itemsLength) {
            this.states.steps.push(Math.round(increment * index));
            index++;
        }
    }
    /////////////
    // Resize
    /////////////
    resize() {
        this.states.maxWidth = this.opts.container.offsetWidth;
        this.setSteps();
        this.goTo(this.states.activeStep, false);
    }
    /////////////
    // Update
    /////////////
    update() {
        const translateValue = this.getTranslateValue();
        const translateValueAlternative = this.getAlternativeTranslateValue();
        const progressValue = this.getProgressValue();
        const velocity = this.getVelocity();
        this.getProgressIndex(progressValue);
        this.getRoundProgressIndex(progressValue);
        this.opts.indicator.style.transform = `translate3d(${translateValue}px, 0, 0)`;
        this.opts.line.style.transform = `translateZ(0) scaleX(${progressValue})`;
        this.opts.indicatorPress.style.transform = `translate3d(${translateValueAlternative}px, 0, 0) rotate(${velocity * -1}deg)`;
    }
}
