export const ButtonMixin = {
    mounted() {
        //@ts-ignore
        this.setCircleButtons();
    },
    methods: {
        setCircleButtons() {
            let index = 0;
            // @ts-ignore
            const $circleButtons = this.$el.querySelectorAll('.js-btn-circle');
            if (!$circleButtons)
                return;
            while (index < $circleButtons.length) {
                const $circleButton = $circleButtons[index];
                const $circleEl = $circleButton.querySelector('.js-btn-circle-el');
                if ($circleEl) {
                    const width = $circleButtons[index].offsetWidth;
                    const height = $circleButtons[index].offsetHeight;
                    const radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
                    $circleEl.style.width = `${radius}px`;
                    $circleEl.style.height = `${radius}px`;
                }
                index++;
            }
        }
    }
};
