import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "./Routes";
import { getGlApp } from "mixins/GLAppProvider";
import { I18n } from 'core/I18n';
Vue.use(VueRouter);
let base = '';
/////////////////////////
/////////////////////
//////////
////////////////////////////
/////////////////////
//////////
const router = new VueRouter({
    mode: 'history',
    base: base,
    routes
});
router.beforeResolve(async (to, from, next) => {
    if (I18n.locale !== to.params.lang) {
        try {
            await I18n.setLocale(to.params.lang);
        }
        catch (error) {
            // Handles non existant language
            console.warn('[Router] Non Existing locale "' + to.params.lang + '", redirecting to "' + I18n.locale + '"');
            next(false);
            router.push({ name: 'game-1', params: { lang: I18n.defaultLocale } });
            return;
        }
    }
    if (to.name == from.name) {
        next();
        return;
    }
    // Instantiate target module
    // Preload target module
    if (to.matched.length > 0) {
        const componentModule = to.matched[0].components.default._module;
        componentModule.instantiateModule();
        componentModule.methods.getModule().init(getGlApp());
        // Preload mandatory asset (loading screen for instance)
        await componentModule.methods.getModule().preload();
    }
    // Leave animation current module
    // Release current module
    if (from.matched.length > 0) {
        const componentModule = from.matched[0].components.default._module;
        await componentModule.methods.getModule().leave();
        await componentModule.releaseModule();
    }
    next();
});
export default router;
