var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-Homepage",
      class: {
        "shipsList-active": _vm.shipsListActive,
        "cursor-active": _vm.cursorActive
      }
    },
    [
      _c("div", { staticClass: "t-layout" }, [
        _c("div", { ref: "numbersDatas", staticClass: "c-Datas" }, [
          _c("div", { staticClass: "c-Datas__inner" }, [
            _c(
              "ul",
              { staticClass: "c-Datas__list t-list" },
              _vm._l(_vm.numbers, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "c-Datas__item",
                    class: { "is-active": index === _vm.activeNumberIndex }
                  },
                  [
                    _c("p", { staticClass: "c-Datas__content" }, [
                      _c("span", { staticClass: "c-Datas__number" }, [
                        _vm._v(_vm._s(item.number))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "c-Datas__text" }, [
                        _vm._v(_vm._s(item.text))
                      ])
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "c-Datas__line" },
              [
                _c("ui-icon", {
                  staticClass: "c-Datas__line__join c-Datas__line__join--left",
                  attrs: { type: "join", color: "light" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "c-Datas__line__trait" }),
                _vm._v(" "),
                _c("ui-icon", {
                  staticClass: "c-Datas__line__join c-Datas__line__join--right",
                  attrs: { type: "join", color: "light" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "c-Homepage__burger t-btn t-btn--action--l",
            on: { click: _vm.openAbout }
          },
          [
            _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
              _vm._v(_vm._s(_vm.t("views.common.cta_close")))
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "t-btn__icon" },
              [_c("ui-icon", { attrs: { type: "menu", color: "light" } })],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "c-Hotspots" }, [
          _c(
            "ul",
            { ref: "hotspotlist", staticClass: "c-Hotspots__list" },
            _vm._l(_vm.ships, function(item, index) {
              return _c(
                "li",
                {
                  key: index,
                  ref: "hotspots",
                  refInFor: true,
                  staticClass: "c-Hotspots__list__item c-HS is-active"
                },
                [
                  _c("div", { staticClass: "c-HS" }, [
                    _c("span", { staticClass: "c-HS__label" }, [
                      _c("span", { staticClass: "c-HS__label__top" }, [
                        _c("span", { staticClass: "c-HS__label__masked" }, [
                          _vm._v(_vm._s(item.firstName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "c-HS__label__bottom" }, [
                        _c("span", { staticClass: "c-HS__label__masked" }, [
                          _vm._v(_vm._s(item.lastName))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "c-HS__label__hover" }, [
                      _c("span", { staticClass: "c-HS__label__hover__top" }, [
                        _c(
                          "span",
                          { staticClass: "c-HS__label__hover__masked" },
                          [_vm._v(_vm._s(item.firstName))]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "c-HS__label__hover__bottom" },
                        [
                          _c(
                            "span",
                            { staticClass: "c-HS__label__hover__masked" },
                            [_vm._v(_vm._s(item.lastName))]
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    ref: "hotspotInteractiveZone",
                    refInFor: true,
                    staticClass: "c-Hotspots__list__item__interactive-zone"
                  })
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-Homepage__ships-action" }, [
          _c(
            "button",
            {
              ref: "fleetBtn",
              staticClass: "t-btn t-btn--action--label",
              on: { click: _vm.onBrowseFleet }
            },
            [
              _c("span", { staticClass: "t-btn__label" }, [
                _vm._v(_vm._s(_vm.t("views.common.cta_fleet")))
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "t-btn__icon" },
                [
                  _c("div", { staticClass: "t-btn__circle" }),
                  _vm._v(" "),
                  _c("ui-icon", { attrs: { type: "logo", color: "dark" } })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "t-btn__hover" }, [
                _c("span", { staticClass: "t-btn__circle__hover" }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "t-btn__icon__hover" },
                  [_c("ui-icon", { attrs: { type: "logo", color: "light" } })],
                  1
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-Homepage__action" }, [
          _c(
            "button",
            {
              ref: "donateBtn",
              staticClass: "t-btn t-btn--action--dbig",
              on: { click: _vm.goDonate }
            },
            [
              _c("span", { staticClass: "t-btn__circle t-btn__circle__intro" }),
              _vm._v(" "),
              _c("span", {
                staticClass: "t-btn__circle t-btn__circle__default"
              }),
              _vm._v(" "),
              _c("span", { staticClass: "t-btn__circle t-btn__circle__hover" }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("span", { staticClass: "t-btn__circle t-btn__circle__end" }),
              _vm._v(" "),
              _vm._m(1)
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { ref: "mobileAction", staticClass: "c-Homepage__mobile-action" },
          [
            _c(
              "button",
              {
                staticClass: "t-btn t-btn--action--d",
                on: { click: _vm.goDonate }
              },
              [
                _c("span", { staticClass: "t-btn__label" }, [
                  _vm._v(_vm._s(_vm.t("views.common.cta_action_top"))),
                  _c("br"),
                  _vm._v(
                    _vm._s(_vm.t("views.common.cta_action_bottom")) +
                      "\n        "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "t-btn t-btn--action--l",
                on: { click: _vm.onBrowseFleet }
              },
              [
                _c("span", { staticClass: "t-btn__label" }, [
                  _vm._v(_vm._s(_vm.t("views.common.cta_fleet")))
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "c-ShipsList" }, [
          _c(
            "div",
            {
              ref: "scroller",
              staticClass: "c-ShipsList__scroller",
              on: {
                mouseenter: _vm.onShipListEnter,
                mouseleave: _vm.onShipListLeave,
                mousemove: _vm.onShipListMove
              }
            },
            [
              _c(
                "ul",
                { ref: "slider", staticClass: "c-ShipsList__list t-list" },
                _vm._l(_vm.ships, function(ship, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      ref: "slide",
                      refInFor: true,
                      staticClass: "c-ShipsCard",
                      class: {
                        "is-active":
                          ship.slug === _vm.$appdatas.store.currentShip,
                        "is-interactive": _vm.shipsInteractive
                      },
                      on: {
                        click: function($event) {
                          return _vm.showShipView(index)
                        },
                        mouseenter: function($event) {
                          return _vm.onShipItemEnter(index)
                        },
                        mouseleave: _vm.onShipItemLeave
                      }
                    },
                    [
                      _c("div", { staticClass: "c-ShipsCard__slide" }, [
                        _c("div", { staticClass: "c-ShipsCard__slide__mask" }, [
                          _c(
                            "div",
                            { staticClass: "c-ShipsCard__slide__masked" },
                            [
                              _c("div", { staticClass: "c-ShipsCard__inner" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-ShipsCard__content" },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass: "c-ShipsCard__title t-h2"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "c-ShipsCard__title__prefix"
                                          },
                                          [_vm._v(_vm._s(ship.prefix))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "c-ShipsCard__title__name"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "c-ShipsCard__title__name__block"
                                              },
                                              [_vm._v(_vm._s(ship.firstName))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "c-ShipsCard__title__name__block"
                                              },
                                              [_vm._v(_vm._s(ship.lastName))]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "c-ShipsCard__description t-text--sm"
                                      },
                                      [_vm._v(_vm._s(ship.baseline))]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      staticClass: "c-ShipsCard__location",
                                      domProps: {
                                        innerHTML: _vm._s(ship.location)
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "c-ShipsCard__picture" },
                                [
                                  _vm.thumbnails[ship.slug]
                                    ? _c(
                                        "object-fit",
                                        {
                                          staticClass:
                                            "c-ShipsCard__picture__fit",
                                          attrs: {
                                            width:
                                              _vm.thumbnails[ship.slug].width,
                                            height:
                                              _vm.thumbnails[ship.slug].height
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "c-ShipsCard__picture__fit__img js-object-fit",
                                            attrs: {
                                              src: _vm.thumbnails[ship.slug].src
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "c-ShipsList__list c-ShipsList__list--duplicate",
                  attrs: { "aria-hidden": "true" }
                },
                _vm._l(_vm.ships, function(ship, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      ref: "duplicateSlide",
                      refInFor: true,
                      staticClass: "c-ShipsCard",
                      class: {
                        "is-active":
                          ship.slug === _vm.$appdatas.store.currentShip,
                        "is-interactive": _vm.shipsInteractive
                      },
                      on: {
                        click: function($event) {
                          return _vm.showShipView(index)
                        },
                        mouseenter: function($event) {
                          return _vm.onShipItemEnter(index)
                        },
                        mouseleave: _vm.onShipItemLeave
                      }
                    },
                    [
                      _c("div", { staticClass: "c-ShipsCard__slide" }, [
                        _c("div", { staticClass: "c-ShipsCard__slide__mask" }, [
                          _c(
                            "div",
                            { staticClass: "c-ShipsCard__slide__masked" },
                            [
                              _c("div", { staticClass: "c-ShipsCard__inner" }, [
                                _c(
                                  "div",
                                  { staticClass: "c-ShipsCard__content" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "c-ShipsCard__title t-h2"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "c-ShipsCard__title__prefix"
                                          },
                                          [_vm._v(_vm._s(ship.prefix))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "c-ShipsCard__title__name"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "c-ShipsCard__title__name__block"
                                              },
                                              [_vm._v(_vm._s(ship.firstName))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "c-ShipsCard__title__name__block"
                                              },
                                              [_vm._v(_vm._s(ship.lastName))]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "c-ShipsCard__description t-text--sm"
                                      },
                                      [_vm._v(_vm._s(ship.baseline))]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      staticClass: "c-ShipsCard__location",
                                      domProps: {
                                        innerHTML: _vm._s(ship.location)
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "c-ShipsCard__picture" },
                                [
                                  _vm.thumbnails[ship.slug]
                                    ? _c(
                                        "object-fit",
                                        {
                                          staticClass:
                                            "c-ShipsCard__picture__fit",
                                          attrs: {
                                            width:
                                              _vm.thumbnails[ship.slug].width,
                                            height:
                                              _vm.thumbnails[ship.slug].height
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "c-ShipsCard__picture__fit__img js-object-fit",
                                            attrs: {
                                              src: _vm.thumbnails[ship.slug].src
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("div", {
            ref: "shipsListCursor",
            staticClass: "c-ShipsList__cursor t-btn t-btn--action--d"
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "c-ShipsList__close-btn t-btn t-btn--action--l",
              on: { click: _vm.onCloseFleet }
            },
            [
              _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
                _vm._v("close")
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "t-btn__icon" },
                [
                  _c("ui-icon", {
                    attrs: { type: "cross-mobile", color: "dark" }
                  })
                ],
                1
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-Hotspots" }, [
        _c(
          "ul",
          { ref: "hotspotlist", staticClass: "c-Hotspots__list" },
          _vm._l(_vm.ships, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                ref: "hotspots",
                refInFor: true,
                staticClass: "c-Hotspots__list__item"
              },
              [
                _c("span", {
                  staticClass: "c-Hotspots__list__item__label",
                  domProps: { innerHTML: _vm._s(item.fullName) }
                }),
                _vm._v(" "),
                _c("div", {
                  ref: "hotspotInteractiveZone",
                  refInFor: true,
                  staticClass: "c-Hotspots__list__item__interactive-zone"
                })
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.ships, function(ship, index) {
        return _c("c-ship", {
          key: index,
          ref: "shipViews",
          refInFor: true,
          attrs: { ship: ship, image: _vm.covers[ship.slug] },
          on: {
            close: _vm.hideShipView,
            closeAll: function($event) {
              return _vm.hideShipView(true)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "t-btn__label" }, [
      _c("span", { staticClass: "t-btn__label__top" }, [
        _c("span", { staticClass: "t-btn__label__masked" }, [_vm._v("Take")])
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "t-btn__label__bottom" }, [
        _c("span", { staticClass: "t-btn__label__masked" }, [_vm._v("action")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "t-btn__label__hover" }, [
      _c("span", { staticClass: "t-btn__label__hover__top" }, [
        _c(
          "span",
          {
            staticClass: "t-btn__label__hover__masked",
            attrs: { "aria-hidden": "true" }
          },
          [_vm._v("Take")]
        )
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "t-btn__label__hover__bottom" }, [
        _c(
          "span",
          {
            staticClass: "t-btn__label__hover__masked",
            attrs: { "aria-hidden": "true" }
          },
          [_vm._v("action")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }