import AudioConfig from "./AudioConfig";
import AudioSource from "core/audio/AudioSource";
let mod = null;
export function GetAudioModule() {
    if (mod == null) {
        mod = new AudioModule();
    }
    return mod;
}
export function ReleaseAudioModule() {
    mod = null;
}
export default class AudioModule {
    constructor() {
        // this.context = new ((<any>window).AudioContext || (<any>window).webkitAudioContext)();
        this.onStateChange = () => {
            if (this.context.state === 'running') {
                this._ctxReadyResolver();
            }
        };
        // this.masterNode = this.context.createGain();
        // this.masterNode.gain.value = 1.0;
        // this.player = new AudioPlayer(this);
        // this.masterNode.connect(this.context.destination);
        // this._ctxReady = new Promise((resolve) => {
        //     this._ctxReadyResolver = resolve;
        // });
        // document.addEventListener("mousedown", () => {
        //     if (this.context.state === 'suspended') {
        //         this.context.resume();
        //     }
        // });
        // this.context.onstatechange = this.onStateChange;
        // this.onStateChange();
    }
    createAudioSource(name, opts) {
        var url = AudioConfig.asset_url(name + '.mp3');
        var s = new AudioSource(this, url, opts);
        return s;
    }
    getReadyState() {
        return this._ctxReady;
    }
}
