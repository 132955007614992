import Sound from "core/audio/Sound";
import * as Net from 'core/resource/Net';
export default class AudioSource {
    constructor(module, url, opts) {
        this.bufferSource = null;
        this.module = module;
        this.url = url;
        this.output = (opts.output === undefined) ? null : opts.output;
    }
    load() {
        if (this.bufferSource !== null)
            return null;
        return Net.loadBytes(this.url)
            .then(this.decodeBuffer.bind(this));
    }
    decodeBuffer(buffer) {
        return new Promise((resolve) => {
            this.module.context.decodeAudioData(buffer, (audioBuffer) => {
                this.bufferSource = audioBuffer;
                resolve(audioBuffer);
            });
        });
    }
    play(volume, loop = false) {
        if (volume === undefined)
            volume = 1.0;
        if (loop === undefined)
            loop = false;
        var sound = new Sound(this);
        sound.init(volume, loop);
        return sound;
    }
    dispose() {
        this.bufferSource = null;
    }
}
