import WebFont from "webfontloader";
import { seconds } from "lol/js/promise/time";
import lottie from 'lottie-web';
import crossAnimationData from 'assets/bodymovin/bodymovin_cross.json';
import UIIcon from "components/ui-icon/ui-icon.vue";
import ObjectFit from "components/object-fit/object-fit.vue";
import MainVideo from "components/main-video/main-video.vue";
import { SectionMixin } from "mixins/SectionMixin";
import { ButtonMixin } from "mixins/ButtonMixin";
import { VIEWS } from "datas";
const FONTLOAD_PERCENT_PART = .1;
const VIDEO_PERCENT_PART = .1;
const GL_PERCENT_PART = .5;
export default {
  mixins: [SectionMixin, ButtonMixin],

  data() {
    return {
      isReady: false,
      isLoadingBarReady: false,
      loaderUIProgress: 0,
      loaderPercent: 0,
      activeSectionIndex: 0
    };
  },

  props: {
    introductionImg: {
      type: HTMLImageElement | Object
    },
    logoImg: {
      type: HTMLImageElement | Object
    }
  },
  components: {
    "ui-icon": UIIcon,
    ObjectFit,
    MainVideo
  },
  computed: {
    loaderProgress() {
      return Math.round(this.loaderUIProgress * 100);
    }

  },

  created() {
    this.loadWebfonts();
    this.isFirstTime = true;
  },

  mounted() {
    // Load cross Animation
    this.crossAnimation = lottie.loadAnimation({
      animationData: crossAnimationData,
      container: this.$refs.crossContainer,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    });
    this.$watch('$appdatas.store.xpLoadProgress', this.loadGLResources);
    this.$watch('$appdatas.store.xpLoaded', this.completeGLResources);
  },

  methods: {
    enterComponent() {
      this.enterAnimation();

      if (!this.isFirstTime) {
        this.$refs.video.enter();
      }

      this.isFirstTime = false;
    },

    leaveComponent() {
      // Unbind background image events
      this.$refs.visual && this.$refs.visual.unbindEvents(); // Unbind video events

      this.$refs.video && this.$refs.video.unbindEvents();
      this.$refs.video.leave(); // Logo      

      this.leaveAnimation();
    },

    async enterAnimation() {
      await seconds(.5);
      this.$refs.introLogo.classList.add('is-appear');
      this.$refs.introContent.classList.add('is-appear');
      this.$refs.visual.$el.classList.add('is-appear');
      await seconds(1.6);
      this.crossAnimation.play();
      if (this.isLoadingBarReady) return this.$refs.discoverButton.classList.add('is-appear');
      this.isLoadingBarReady = true;
      await seconds(.4);
      this.$watch('loaderPercent', this.onLoaderProgress, {
        immediate: true
      });
      this.tryToBeReady();
    },

    async leaveAnimation() {
      this.$refs.introLogo.classList.remove('is-appear');
      this.$refs.introContent.classList.remove('is-appear');
      this.$refs.visual.$el.classList.remove('is-appear');
      this.$refs.discoverButton.classList.remove('is-appear');
      this.$refs.skipButton.classList.remove('is-appear');
      setTimeout(() => {
        this.crossAnimation.goToAndStop(0);
      }, this.$appdatas.store.fadeViewDuration);
    },

    /////////////
    // Video methods
    /////////////
    playVideo() {
      if (!this.$refs.video || !this.$refs.video.$refs.video || this.videoPlaying) return; // Dispose sound
      // this.$appdatas.audioLayout.dispose()
      // Get video

      const $video = this.$refs.video.$refs.video; // Reset video

      $video.currentTime = 0; // Play video

      $video.play(); // Set flag

      this.videoPlaying = true; // Active index

      this.activeSectionIndex = 1; // Show button

      this.$nextTick(() => {
        this.$refs.skipButton.classList.add('is-appear');
      });
    },

    stopVideo() {
      if (!this.$refs.video || this.videoEnded) return; // Get video

      const $video = this.$refs.video.$refs.video; // Pause video

      $video.pause(); // Set flag

      this.videoEnded = true; // Complete preload section

      this.tryPreloadComplete();
    },

    visibilityPlayVideo() {
      if (!this.$refs.video || !this.$refs.video.$refs.video || this.videoPlaying || !this.activeSectionIndex) return; // Get video

      const $video = this.$refs.video.$refs.video; // Play video

      $video.play(); // Set flag

      this.videoPlaying = true;
    },

    visibilityPauseVideo() {
      if (!this.$refs.video || !this.$refs.video.$refs.video || this.videoEnded || !this.videoPlaying || !this.activeSectionIndex) return; // Get video

      const $video = this.$refs.video.$refs.video; // Pause video

      $video.pause(); // Set flag

      this.videoPlaying = false;
    },

    onVideoLoaded() {
      // Compute loader percent ( add Fontloader part )
      this.loaderPercent += VIDEO_PERCENT_PART; // Set flag

      this.videoLoaded = true; // Try to show discover button

      this.tryToBeReady();
    },

    /////////////
    // Complete preload
    /////////////
    async tryToBeReady() {
      if (this.webfontsLoaded && this.resourcesLoaded && this.videoLoaded && this.glResourcesLoaded && this.isLoadingBarReady) {
        this.loaderPercent = 1; // Button appear

        if (!this.isReady) {
          // Is ready
          this.isReady = true;
          await seconds(1.233);
          this.$refs.discoverButton.classList.add('is-appear');
        }
      }
    },

    tryPreloadComplete() {
      if (this.webfontsLoaded && this.resourcesLoaded && this.videoLoaded && this.videoEnded && this.glResourcesLoaded && this.isLoadingBarReady) {
        // Signal to go to next view
        this.$emit("nextView");
        return true;
      } else {
        return false;
      }
    },

    /////////////
    // Loaders
    /////////////
    onLoaderProgress(value) {
      this.loaderUIProgress = value;
    },

    //////////////
    // Resources
    watchResource(resourceGroup) {
      let loadables = [];
      resourceGroup.collectAllLoadables(loadables);
      this.resCount = loadables.length;
      this.loadCount = 0;
      loadables.map(loadable => {
        loadable.then(this.onResourceLoaded);
      });
    },

    async onResourceLoaded(resource) {
      this.loadCount += 1; // Compute loader percent (without Fontloader and Video Loader)

      this.loaderPercent = this.loadCount / this.resCount * (1 - (FONTLOAD_PERCENT_PART + VIDEO_PERCENT_PART + GL_PERCENT_PART)); // If resources loaded

      if (this.loadCount == this.resCount) {
        // Set flag
        this.resourcesLoaded = true; // Try to show discover button

        this.tryToBeReady();
      }
    },

    //////////////
    // GL Resources
    loadGLResources(value, lastValue) {
      this.loaderPercent += (value - lastValue) * GL_PERCENT_PART;
    },

    completeGLResources(value) {
      // Set flag
      this.glResourcesLoaded = value; // Try to show discover button

      if (this.glResourcesLoaded) this.tryToBeReady();
    },

    //////////////
    // Webfonts
    loadWebfonts() {
      WebFont.load({
        classes: false,
        loading: () => {//console.log(`%c${'Fonts are loading'}`, `background: #222; color: #ed3276;`);
        },
        active: () => {
          //console.log(`%c${'Fonts have rendered'}`, `background: #222; color: #ed3276;`);
          // Webfonts loaded
          this.onWebfontsLoaded();
        },
        inactive: () => {//console.log(`%c${'The browser does not support linked fonts or none of the fonts could be loaded'}`, `background: #222; color: #ed3276;`);
        },
        custom: {
          families: ["Poppins-Light:n4", "Poppins-Regular:n4", "Poppins-Medium:n4", "Monarch:n4"],
          testStrings: {
            "Poppins-Light": "nofishing.net",
            "Poppins-Regular": "Hello World",
            "Poppins-Medium": "Discover",
            "Monarch": "Soon, there won’t be no fishes, no ocean. It will only be fishing nets."
          }
        }
      });
    },

    onWebfontsLoaded() {
      // Compute loader percent ( add Fontloader part )
      this.loaderPercent += FONTLOAD_PERCENT_PART; // Set flag

      this.webfontsLoaded = true; // Try to show discover button

      this.tryToBeReady();
    },

    reset() {
      this.activeSectionIndex = 0;
      this.videoPlaying = false;
      this.videoEnded = false;
    }

  }
};