import Module from "core/Module";
import { ImageResource } from "core/resource/ImageResource";
export default class LayoutModule extends Module {
    constructor() {
        super();
        this.name = "Layout";
    }
    init(glapp) {
        super.init(glapp);
        let name = (window.innerWidth >= 768) ? 'desktop' : 'mobile';
        // Preload resources
        new ImageResource("landing-background", require('assets/img/landing-' + name + '.jpg'), this.preloadedResources);
        new ImageResource("logo", require('assets/img/logo-light.png'), this.preloadedResources);
        new ImageResource("bob_barker", require('assets/img/cover/bob_barker.jpg'), this.resources);
        new ImageResource("brigitte_bardot", require('assets/img/cover/brigitte_bardot.jpg'), this.resources);
        new ImageResource("farley_mowat", require('assets/img/cover/farley_mowat.jpg'), this.resources);
        new ImageResource("jairo_mora_sandoval", require('assets/img/cover/jairo_mora_sandoval.jpg'), this.resources);
        new ImageResource("john_paul_dejoria", require('assets/img/cover/john_paul_dejoria.jpg'), this.resources);
        new ImageResource("martin_sheen", require('assets/img/cover/martin_sheen.jpg'), this.resources);
        new ImageResource("ocean_warrior", require('assets/img/cover/ocean_warrior.jpg'), this.resources);
        new ImageResource("sam_simon", require('assets/img/cover/sam_simon.jpg'), this.resources);
        new ImageResource("white_holly", require('assets/img/cover/white_holly.jpg'), this.resources);
        new ImageResource("bob_barker_thumbnail", require('assets/img/thumbnail/bob_barker.jpg'), this.resources);
        new ImageResource("brigitte_bardot_thumbnail", require('assets/img/thumbnail/brigitte_bardot.jpg'), this.resources);
        new ImageResource("farley_mowat_thumbnail", require('assets/img/thumbnail/farley_mowat.jpg'), this.resources);
        new ImageResource("jairo_mora_sandoval_thumbnail", require('assets/img/thumbnail/jairo_mora_sandoval.jpg'), this.resources);
        new ImageResource("john_paul_dejoria_thumbnail", require('assets/img/thumbnail/john_paul_dejoria.jpg'), this.resources);
        new ImageResource("martin_sheen_thumbnail", require('assets/img/thumbnail/martin_sheen.jpg'), this.resources);
        new ImageResource("ocean_warrior_thumbnail", require('assets/img/thumbnail/ocean_warrior.jpg'), this.resources);
        new ImageResource("sam_simon_thumbnail", require('assets/img/thumbnail/sam_simon.jpg'), this.resources);
        new ImageResource("white_holly_thumbnail", require('assets/img/thumbnail/white_holly.jpg'), this.resources);
        // SET AUDIO
        // datas.audioLayout = new Audio(this.audioResources);
        // this.audioModule = GetAudioModule();
    }
    setVolume(volume = 0.0) {
        // this.audioModule.masterNode.gain.value = volume;
    }
}
