import Vue from "vue";
import { registerGLApp } from "mixins/GLAppProvider";
import GLViewer from 'components/misc/GLViewer.vue';
import Layout from 'modules/layout/Layout.vue';
export default {
  name: 'app',
  components: {
    GLViewer,
    Layout
  },

  beforeCreate() {
    Layout._module.instantiateModule();
  },

  created() {
    Layout._module.methods.getModule().init(null);
  },

  mounted() {
    registerGLApp(this.$refs.viewer.glapp);
  }

};