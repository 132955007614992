var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-MainVideo" }, [
    _c(
      "video",
      {
        ref: "video",
        staticClass: "video",
        attrs: {
          src: "../assets/video/sea-shepherd-" + _vm.suffix + ".mp4",
          preload: "auto",
          type: "video/mp4",
          playsinline: ""
        }
      },
      [
        _c("track", {
          attrs: {
            label: "French",
            kind: "subtitles",
            srclang: "fr",
            src: "../assets/vtt/sub-fr.vtt",
            default: _vm.currentLocale === "fr"
          }
        }),
        _vm._v(" "),
        _c("track", {
          attrs: {
            label: "English",
            kind: "subtitles",
            srclang: "en",
            src: "../assets/vtt/sub-en.vtt",
            default: _vm.currentLocale === "en"
          }
        })
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "c-MainVideo__subtitles t-text--xxxl",
      class: {
        "c-MainVideo__subtitles--hidden":
          !_vm.subtitlesEnabled || !_vm.subtitlesText
      },
      domProps: { innerHTML: _vm._s(_vm.subtitlesText) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }