import { TextureType } from "nanogl/texture-base";
import Deferred from "utils/Deferred";
import { TextureDataType } from "./TextureData";
const _HAS_CIB = (window.createImageBitmap !== undefined);
function getImageType(bytes) {
    const H = new Uint32Array(bytes, 0, 2);
    if (H[0] === 0x474e5089) {
        return 'image/png';
    }
    return 'image/jpeg';
}
export default class TextureCodecStd {
    constructor() {
        this.name = 'std';
    }
    decodeImage(bytes) {
        const imageType = getImageType(bytes);
        this.isPNG = imageType == 'image/png' ? true : false;
        const blob = new Blob([bytes], { type: imageType });
        if (_HAS_CIB) {
            return createImageBitmap(blob);
        }
        else {
            const def = new Deferred();
            const src = URL.createObjectURL(blob);
            const img = new Image();
            img.onload = def.resolve;
            img.onerror = def.reject;
            img.src = src;
            def.promise.finally(() => URL.revokeObjectURL(src));
            return def.promise.then(() => img);
        }
    }
    async decodeLod(source, lod) {
        const requestLod = source.lods[lod];
        const image = await this.decodeImage(requestLod.buffers[0]);
        const mip = {
            width: image.width,
            height: image.height,
            data: image
        };
        const fmt = this.isPNG ? 0x1908 : 0x1907;
        source.datas = {
            datatype: TextureDataType.IMAGE,
            format: fmt,
            internalformat: fmt,
            type: 0x1401,
            textureType: TextureType.TEXTURE_2D,
            width: 1024,
            height: 1024,
            sources: [{
                    surfaces: [[mip]]
                }]
        };
    }
    async decodeCube(source) {
        source.datas = {
            datatype: TextureDataType.IMAGE,
            format: 0x1907,
            internalformat: 0x1907,
            type: 0x1401,
            textureType: TextureType.TEXTURE_CUBE,
            width: 1024,
            height: 1024,
            sources: [{
                    surfaces: []
                }]
        };
        for (let i = 0; i < 6; i++) {
            const requestLod = source.lods[0];
            const image = await this.decodeImage(requestLod.buffers[0]);
            const mip = {
                width: image.width,
                height: image.height,
                data: image
            };
            source.datas.sources[0].surfaces.push([mip]);
        }
    }
    isSupported(extensions) {
        return true;
    }
}
