import 'dev';
import 'vendors';
import 'styles/index.styl';
import 'datas';
// Prevent circular dependency with Resrouce
import "core/resource/Net";
import Vue from 'vue';
Vue.config.productionTip = false;
import App from './App.vue';
import router from "core/router/Router";
import ResourceGroup from 'core/resource/ResourceGroup';
ResourceGroup.default.load();
import { I18n } from "core/I18n";
import Datas from 'datas';
import { setGeoIP } from 'utils/set-geo-ip';
window.addEventListener("DOMContentLoaded", async (event) => {
    Vue.observable(I18n);
    Datas.store.countryLocation = await setGeoIP();
    I18n.setLocale(window.user_lang)
        .then(() => {
        Vue.mixin({
            computed: {
                currentLocale() {
                    return I18n.locale;
                }
            },
            methods: {
                t(key, data) {
                    return I18n.t(key, data);
                }
            }
        });
        new Vue({
            router,
            render: h => h(App),
        }).$mount('#app');
    });
});
