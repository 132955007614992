import Vue from 'vue';
let resizeTimer = null;
export var VIEWS;
(function (VIEWS) {
    VIEWS["Preloader"] = "preloader";
    VIEWS["Introduction"] = "introduction";
    VIEWS["Homepage"] = "homepage";
    VIEWS["Donation"] = "donation";
})(VIEWS || (VIEWS = {}));
export class Datas {
    constructor() {
        this.store = Vue.observable({
            debug: false,
            screen: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            currentView: VIEWS.Preloader,
            currentShip: "",
            currentShipIdx: -1,
            openedShip: "",
            glActiveShipIdx: -1,
            hotspots: [
                { name: 'boat1', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat2', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat3', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat4', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat5', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat6', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat7', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat8', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat9', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 },
                { name: 'boat10', screenPos: [0, 0], visible: false, faded: false, opacity: 0, dist: 0, overed: 0 }
            ],
            isTouchable: this.isTouchable(),
            isIOS: this.isIOS(),
            introDragProgress: 0,
            donationDragProgressIndex: 0,
            donationDragProgressValue: 0,
            fadeViewDuration: 0.766,
            boatCardPos: [200, 200],
            soundMuted: false,
            popinOpen: false,
            xpLoadProgress: 0,
            xpLoaded: false,
            cardsOpen: false,
            isGlobeInteractive: true,
            isIntroSceneFinished: false
        });
        // TODO MOVE TO OTHER
        this.store.debug = true;
        /////////////////
        //////////////
        // global audio UI (play hover / click sound)
        this.audioLayout = null;
        // Events
        window.addEventListener('resize', this.onResize.bind(this));
        window.addEventListener('deviceorientation', this.onResize.bind(this));
    }
    isTouchable() {
        // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript  
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        const mq = function (query) { return window.matchMedia(query).matches; };
        // @ts-ignore
        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }
        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }
    isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    }
    onResize() {
        if (resizeTimer)
            clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            this.store.screen.width = window.innerWidth;
            this.store.screen.height = window.innerHeight;
            // document.body.scrollTo({left: 0, top: 0})
            // window.scrollTo(0, 0);
            let vw = window.innerWidth * 0.01;
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vw', `${vw}px`);
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }, 500);
    }
}
const datas = new Datas();
function provideAppData() {
    this.$appdatas = datas;
}
Vue.mixin({ beforeCreate: provideAppData });
export default datas;
