var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-Donation" }, [
    _c("div", { staticClass: "t-layout" }, [
      _c("div", { ref: "card", staticClass: "c-Donation__card" }, [
        _c("div", { staticClass: "c-Donation__card__inner" }, [
          _c("div", { staticClass: "c-Donation__card__masked" }, [
            _c("h2", { staticClass: "c-Donation__card__title t-h2" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.t("views.donation.card_title_top")))
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.t("views.donation.card_title_bottom")))
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "c-Donation__card__text t-paragraph" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.t("views.donation.card_content")) +
                  "\n          "
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-Donation__back-action" }, [
        _c(
          "button",
          {
            ref: "backBtn",
            staticClass: "t-btn t-btn--action--label",
            on: { click: _vm.goBack }
          },
          [
            _c("span", { staticClass: "t-btn__label" }, [
              _vm._v(_vm._s(_vm.t("views.common.cta_back")))
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "t-btn__icon" },
              [
                _c("div", { staticClass: "t-btn__circle" }),
                _vm._v(" "),
                _c("ui-icon", { attrs: { type: "cross", color: "dark" } })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "t-btn__hover" }, [
              _c("span", { staticClass: "t-btn__circle__hover" }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "t-btn__icon__hover" },
                [_c("ui-icon", { attrs: { type: "cross", color: "light" } })],
                1
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-Donation__back-mobile-action" }, [
        _c(
          "button",
          { staticClass: "t-btn t-btn--action--l", on: { click: _vm.goBack } },
          [
            _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
              _vm._v(_vm._s(_vm.t("views.common.cta_back")))
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "t-btn__icon" },
              [
                _c("ui-icon", {
                  attrs: { type: "cross-mobile", color: "dark" }
                })
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "slider",
          staticClass: "c-Donation__slider",
          class: {
            "is-appear": _vm.sliderActive,
            "is-grab": _vm.sliderGrab,
            "is-default": _vm.sliderBackGrab
          }
        },
        [
          _c(
            "ul",
            { staticClass: "c-Donation__slider__list t-list" },
            _vm._l(_vm.donations, function(donation, index) {
              return _c(
                "li",
                {
                  key: index,
                  ref: "sliderItems",
                  refInFor: true,
                  staticClass: "c-Donation__slider__item",
                  class: { "is-highlight": index < _vm.progressIndex }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "c-Donation__slider__pastille t-btn",
                      on: {
                        click: function($event) {
                          return _vm.setDonationIndex(index)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "hide-for-viewer" }, [
                        _vm._v(_vm._s(donation.label))
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "c-Donation__slider__pastille__background"
                      })
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { ref: "dragContainer", staticClass: "c-Donation__slider__drag" },
            [
              _c("div", {
                ref: "line",
                staticClass: "c-Donation__slider__line"
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "indicator",
                  staticClass: "c-Donation__slider__indicator"
                },
                [
                  _c("div", {
                    staticClass: "c-Donation__slider__indicator__background"
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "c-Donation__slider__indicator__content" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "c-Donation__slider__indicator__donation t-paragraph"
                        },
                        [
                          _c(
                            "span",
                            {
                              class: {
                                "is-last":
                                  _vm.activeIndex === _vm.donations.length - 1
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.donations[_vm.activeIndex].label)
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        ref: "tridentContainer",
                        staticClass: "c-Donation__slider__indicator__trident"
                      }),
                      _vm._v(" "),
                      _c("div", {
                        ref: "arrowsContainer",
                        staticClass: "c-Donation__slider__indicator__arrows"
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "indicatorPress",
                  staticClass: "c-Donation__slider__indicator__press"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "c-Donation__slider__indicator__press__inner"
                    },
                    [
                      _c("div", {
                        staticClass:
                          "c-Donation__slider__indicator__press__background"
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "c-Donation__slider__indicator__press__value__container"
                        },
                        _vm._l(_vm.donations, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass:
                                "c-Donation__slider__indicator__press__value",
                              class: {
                                "is-prev": index < _vm.roundProgressIndex,
                                "is-current": index === _vm.roundProgressIndex,
                                "is-last": index === _vm.donations.length - 1
                              }
                            },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-Donation__action-mobile" }, [
            _c(
              "button",
              {
                staticClass: "t-link t-btn t-btn--action--d",
                on: { click: _vm.onPanelOpen }
              },
              [
                _c("span", { staticClass: "t-btn__label" }, [
                  _vm._v(_vm._s(_vm.t("views.common.cta_donate")))
                ])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-Donation__action" }, [
        _c(
          "button",
          {
            ref: "donateBtn",
            staticClass: "t-btn t-btn--action--dbig",
            on: { click: _vm.onPanelOpen }
          },
          [
            _c("span", { staticClass: "t-btn__circle t-btn__circle__intro" }),
            _vm._v(" "),
            _c("span", { staticClass: "t-btn__circle t-btn__circle__default" }),
            _vm._v(" "),
            _c("span", { staticClass: "t-btn__circle t-btn__circle__hover" }),
            _vm._v(" "),
            _c("span", { staticClass: "t-btn__label" }, [
              _c("span", { staticClass: "t-btn__label__top" }, [
                _c("span", { staticClass: "t-btn__label__masked" }, [
                  _vm._v(_vm._s(_vm.t("views.common.cta_donate")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "t-btn__circle t-btn__circle__end" }),
            _vm._v(" "),
            _c("span", { staticClass: "t-btn__label__hover" }, [
              _c("span", { staticClass: "t-btn__label__hover__top" }, [
                _c(
                  "span",
                  {
                    staticClass: "t-btn__label__hover__masked",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v(_vm._s(_vm.t("views.common.cta_donate")))]
                )
              ])
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-Donation__panel",
        class: {
          "is-active": _vm.isPanelActive,
          "is-hidden": _vm.isPanelHidden
        }
      },
      [
        _c("div", {
          staticClass: "c-Donation__panel__overlay",
          on: { click: _vm.onPanelClose }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "c-Donation__panel__close__wrapper" }, [
          _c(
            "button",
            {
              ref: "closePanelBtn",
              staticClass:
                "c-Donation__panel__close t-btn t-btn--action--label--dark",
              on: { click: _vm.onPanelClose }
            },
            [
              _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
                _vm._v(_vm._s(_vm.t("views.common.cta_close")))
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "t-btn__icon" },
                [
                  _c("div", { staticClass: "t-btn__circle" }),
                  _vm._v(" "),
                  _c("ui-icon", { attrs: { type: "cross", color: "light" } })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "t-btn__hover" }, [
                _c("span", { staticClass: "t-btn__circle__hover" }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "t-btn__icon__hover" },
                  [_c("ui-icon", { attrs: { type: "cross", color: "light" } })],
                  1
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-Donation__panel__inner" }, [
          _c("div", { staticClass: "c-Donation__panel__mask" }, [
            _c("ul", { staticClass: "c-Donation__panel__list t-list" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    ref: "donateLinkOdd",
                    staticClass: "t-btn t-btn--action--donateLink",
                    attrs: {
                      href: _vm.t("views.donation.cta_donate_fr_link"),
                      target: "_blank"
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__intro"
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__default"
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__hover"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "t-btn__label" }, [
                      _c("span", { staticClass: "t-btn__label__top" }, [
                        _c("span", { staticClass: "t-btn__label__masked" }, [
                          _vm._v(_vm._s(_vm.t("views.donation.cta_donate_fr")))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__end"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "t-btn__label__hover" }, [
                      _c("span", { staticClass: "t-btn__label__hover__top" }, [
                        _c(
                          "span",
                          {
                            staticClass: "t-btn__label__hover__masked",
                            attrs: { "aria-hidden": "true" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.t("views.donation.cta_donate_fr"))
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    ref: "donateLinkEven",
                    staticClass: "t-btn t-btn--action--donateLink",
                    attrs: {
                      href: _vm.t("views.donation.cta_donate_world_link"),
                      target: "_blank"
                    }
                  },
                  [
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__intro"
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__default"
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__hover"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "t-btn__label" }, [
                      _c("span", { staticClass: "t-btn__label__top" }, [
                        _c("span", { staticClass: "t-btn__label__masked" }, [
                          _vm._v(
                            _vm._s(_vm.t("views.donation.cta_donate_world"))
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "t-btn__circle t-btn__circle__end"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "t-btn__label__hover" }, [
                      _c("span", { staticClass: "t-btn__label__hover__top" }, [
                        _c(
                          "span",
                          {
                            staticClass: "t-btn__label__hover__masked",
                            attrs: { "aria-hidden": "true" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.t("views.donation.cta_donate_world"))
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass:
                  "c-Donation__panel__list c-Donation__panel__list--mobile t-list"
              },
              [
                _c("li", { ref: "donateLinkMobileOdd" }, [
                  _c(
                    "a",
                    {
                      staticClass: "t-link t-btn t-btn--action--donation",
                      attrs: {
                        href: _vm.t("views.donation.cta_donate_fr_link"),
                        target: "_blank"
                      }
                    },
                    [
                      _c("span", { staticClass: "t-btn__label" }, [
                        _vm._v(
                          _vm._s(_vm.t("views.donation.cta_donate_mobile_fr"))
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { ref: "donateLinkMobileEven" }, [
                  _c(
                    "a",
                    {
                      staticClass: "t-link t-btn t-btn--action--donation",
                      attrs: {
                        href: _vm.t("views.donation.cta_donate_world_link"),
                        target: "_blank"
                      }
                    },
                    [
                      _c("span", { staticClass: "t-btn__label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.t("views.donation.cta_donate_mobile_world")
                          )
                        )
                      ])
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }