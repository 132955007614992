import { seconds } from "lol/js/promise/time";
import UIIcon from "components/ui-icon/ui-icon.vue";
import ObjectFit from "components/object-fit/object-fit.vue";
import lottie from 'lottie-web';
import crossAnimationData from 'assets/bodymovin/bodymovin_cross_black.json';
export default {
  data() {
    return {
      isActive: false
    };
  },

  props: {
    ship: {
      type: Object,
      required: true
    },
    image: {
      type: HTMLImageElement,
      required: false
    }
  },
  components: {
    "ui-icon": UIIcon,
    ObjectFit
  },

  mounted() {
    // Load cross Animation
    this.crossAnimation = lottie.loadAnimation({
      animationData: crossAnimationData,
      container: this.$refs.crossContainerOdd,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    }); // Load cross Animation

    this.crossAnimation2 = lottie.loadAnimation({
      animationData: crossAnimationData,
      container: this.$refs.crossContainerEven,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    });
  },

  methods: {
    async open() {
      this.isActive = true;
      this.$appdatas.store.isGlobeInteractive = false;
      await seconds(1.666);
      this.$refs.closeBtn.classList.add('is-appear');
      this.crossAnimation.goToAndPlay(0);
      await seconds(0.333);
      this.crossAnimation2.goToAndPlay(0);
    },

    async close() {
      this.isActive = false;
      this.resetBodymovin();
      this.$emit('close');
      await seconds(1);
      this.$appdatas.store.isGlobeInteractive = true;
    },

    async closeAll() {
      this.isActive = false;
      this.resetBodymovin();
      this.$emit('closeAll');
      await seconds(1);
      this.$appdatas.store.isGlobeInteractive = true;
    },

    async resetBodymovin() {
      await seconds(0.666);
      this.$refs.closeBtn.classList.remove('is-appear');
      this.crossAnimation.goToAndStop(0);
      this.crossAnimation2.goToAndStop(0);
    }

  }
};