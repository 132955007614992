export default {
  name: 'ObjectFit',
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'cover'
    }
  },
  computed: {
    aspect() {
      return this.type !== 'cover';
    }

  },

  created() {
    this._resize = this.resize.bind(this);
  },

  mounted() {
    this.$nextTick(() => {
      this.$objectFit = this.$el.querySelector('.js-object-fit');

      if (!this.$objectFit) {
        console.warn('ObjectFit: do not find element ".js-object-fit".');
      }

      this.resize();
      this.bindEvents();
    });
  },

  beforeDestroy() {
    this.unbindEvents();
  },

  methods: {
    bindEvents() {
      this.unwatchWidth = this.$watch('$appdatas.store.screen.width', function () {
        this.resize();
      });
      this.unwatchHeight = this.$watch('$appdatas.store.screen.height', function () {
        this.resize();
      });
    },

    unbindEvents() {
      // Unwatch
      // https://codingexplained.com/coding/front-end/vue-js/adding-removing-watchers-dynamically
      this.unwatchWidth();
      this.unwatchHeight();
    },

    resize() {
      if (!this.$objectFit) return;
      this.parentWidth = this.$el.clientWidth;
      this.parentHeight = this.$el.clientHeight;
      this.computedSize = this.calculateAspectRatio(this.width, this.height, this.parentWidth, this.parentHeight, this.aspect);
      this.offsetX = Math.round((this.parentWidth - this.computedSize.width) * 0.5);
      this.offsetY = Math.round((this.parentHeight - this.computedSize.height) * 0.5); // debug
      // console.log(this.$el, this.computedSize, this.offsetX, this.offsetY);

      if (this.parentHeight === 0) {
        this.offsetY = 0;
      }

      this.$objectFit.style.width = `${Math.round(this.computedSize.width)}px`;
      this.$objectFit.style.height = `${Math.round(this.computedSize.height)}px`;
      this.$objectFit.style['transform'] = `translate(${this.offsetX}px, ${this.offsetY}px)`;
    },

    calculateAspectRatio(srcWidth, srcHeight, maxWidth, maxHeight, fit) {
      var ratio = [maxWidth / srcWidth, maxHeight / srcHeight];
      ratio = fit ? Math.min(ratio[0], ratio[1]) : Math.max(ratio[0], ratio[1]);
      return {
        width: srcWidth * ratio,
        height: srcHeight * ratio
      };
    }

  }
};