var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-Layout",
      class: [
        "c-Layout--" + _vm.$appdatas.store.currentView,
        { "is-animating": _vm.isAnimating }
      ]
    },
    [
      _vm.isLoaded
        ? _c(
            "header",
            {
              staticClass: "c-Header",
              class: {
                "is-active":
                  _vm.$appdatas.store.currentView != _vm.views.Preloader
              }
            },
            [
              _c("div", { staticClass: "t-layout" }, [
                _c("div", { staticClass: "c-Header__inner" }, [
                  _c("h1", { staticClass: "hide-for-viewer" }, [
                    _vm._v(_vm._s(_vm.t("views.common.site_title")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "c-Header__logo t-btn",
                      on: {
                        click: function($event) {
                          return _vm.resetViews()
                        }
                      }
                    },
                    [
                      _c("ui-icon", { attrs: { type: "logo", color: "light" } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "c-Header__list t-list" }, [
                    _c("li", { staticClass: "c-Header__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "c-Header__link t-btn",
                          on: { click: _vm.openAboutPanel }
                        },
                        [_vm._v(_vm._s(_vm.t("views.common.menu_project")))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "c-Header__item" }, [
                      _vm.navigatorShare
                        ? _c(
                            "button",
                            {
                              staticClass: "c-Header__link t-link t-btn",
                              on: { click: _vm.onShare }
                            },
                            [_vm._v(_vm._s(_vm.t("views.common.menu_share")))]
                          )
                        : _c(
                            "button",
                            {
                              ref: "share",
                              staticClass: "c-Header__link t-link t-btn",
                              attrs: { "data-clipboard-text": _vm.clipText }
                            },
                            [_vm._v(_vm._s(_vm.t("views.common.menu_share")))]
                          )
                    ]),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "c-Header__item" },
                      [
                        _vm.currentLocale === "en"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "c-Header__link t-btn",
                                attrs: {
                                  to: { name: "game-1", params: { lang: "fr" } }
                                }
                              },
                              [
                                _c("span", [_vm._v("en")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("/")]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "is-not-currentLocale" },
                                  [_vm._v("fr")]
                                )
                              ]
                            )
                          : _vm.currentLocale === "fr"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "c-Header__link t-btn",
                                attrs: {
                                  to: { name: "game-1", params: { lang: "en" } }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "is-not-currentLocale" },
                                  [_vm._v("en")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("/")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("fr")])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "c-Header__copied-text c-share",
                    class: { "is-active": _vm.copiedText }
                  },
                  [
                    _c("span", { staticClass: "c-share__panel__before" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "c-share__label" }, [
                      _c("span", { staticClass: "c-share__label__masked" }, [
                        _vm._v(_vm._s(_vm.t("views.common.copied_text")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "c-share__panel__after" })
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c("preloader", {
            ref: _vm.views.Preloader,
            staticClass: "c-View",
            class: {
              "is-active":
                _vm.$appdatas.store.currentView === _vm.views.Preloader
            },
            attrs: {
              "introduction-img": this.getResource("landing-background"),
              "logo-img": this.getResource("logo")
            },
            on: {
              nextView: function($event) {
                return _vm.setView(_vm.views.Introduction)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded ? _c("router-view") : _vm._e(),
      _vm._v(" "),
      _c("introduction", {
        ref: _vm.views.Introduction,
        staticClass: "c-View",
        class: {
          "is-active":
            _vm.$appdatas.store.currentView === _vm.views.Introduction
        },
        on: {
          nextView: function($event) {
            return _vm.setView(_vm.views.Homepage)
          }
        }
      }),
      _vm._v(" "),
      _c("homepage", {
        ref: _vm.views.Homepage,
        staticClass: "c-View",
        class: {
          "is-active": _vm.$appdatas.store.currentView === _vm.views.Homepage
        },
        attrs: { covers: _vm.covers, thumbnails: _vm.thumbnails },
        on: {
          nextView: function($event) {
            return _vm.setView(_vm.views.Donation)
          },
          onOpenAbout: _vm.openAboutPanel
        }
      }),
      _vm._v(" "),
      _c("donation", {
        ref: _vm.views.Donation,
        staticClass: "c-View",
        class: {
          "is-active": _vm.$appdatas.store.currentView === _vm.views.Donation
        },
        on: {
          nextView: function($event) {
            return _vm.setView(_vm.views.Homepage)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "article",
        {
          staticClass: "c-About t-article",
          class: { "is-active": _vm.aboutActive }
        },
        [
          _c("div", { staticClass: "c-About__inner" }, [
            _c("div", { staticClass: "c-About__masked" }, [
              _c("div", { staticClass: "c-About__visual" }, [
                _c(
                  "div",
                  { staticClass: "c-About__visual__masked" },
                  [
                    _vm.aboutImage
                      ? _c(
                          "object-fit",
                          {
                            ref: "visual",
                            staticClass: "c-About__picture",
                            attrs: {
                              width: _vm.aboutImage.width,
                              height: _vm.aboutImage.height
                            }
                          },
                          [
                            _c("img", {
                              staticClass:
                                "c-About__picture__img js-object-fit",
                              attrs: { src: _vm.aboutImage.src }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "c-About__action" }, [
                  _c(
                    "button",
                    {
                      ref: "playFilmBtn",
                      staticClass: "t-btn t-btn--action--altbig",
                      on: { click: _vm.openVideoPanel }
                    },
                    [
                      _c("span", {
                        staticClass: "t-btn__circle t-btn__circle__intro"
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "t-btn__circle t-btn__circle__default"
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "t-btn__circle t-btn__circle__hover"
                      }),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "t-btn__circle t-btn__circle__end"
                      }),
                      _vm._v(" "),
                      _vm._m(1)
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "c-About__action__mobile" }, [
                  _c(
                    "button",
                    {
                      staticClass: "t-btn t-btn--action--b",
                      on: { click: _vm.openVideoPanel }
                    },
                    [
                      _c("span", { staticClass: "t-btn__label" }, [
                        _vm._v("Play Film")
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c-About__header t-header" }, [
                _c("div", { staticClass: "c-About__header__content" }, [
                  _c(
                    "button",
                    {
                      ref: "closeBtn",
                      staticClass:
                        "c-About__close t-btn t-btn--action--label--dark",
                      on: { click: _vm.closeAboutPanel }
                    },
                    [
                      _c("span", { staticClass: "t-btn__label" }, [
                        _vm._v(_vm._s(_vm.t("views.common.cta_close")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "t-btn__icon" },
                        [
                          _c("div", { staticClass: "t-btn__circle" }),
                          _vm._v(" "),
                          _c("ui-icon", {
                            attrs: { type: "cross", color: "light" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "t-btn__hover" }, [
                        _c("span", { staticClass: "t-btn__circle__hover" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "t-btn__icon__hover" },
                          [
                            _c("ui-icon", {
                              attrs: { type: "cross", color: "light" }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "c-About__logo t-btn",
                      on: {
                        click: function($event) {
                          return _vm.resetViews()
                        }
                      }
                    },
                    [_c("ui-icon", { attrs: { type: "logo", color: "dark" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "c-About__link__mobile t-paragraph" },
                    [_vm._v(_vm._s(_vm.t("views.about.title")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-About__header__text" }, [
                    _c("p", { staticClass: "t-paragraph" }, [
                      _vm._v(_vm._s(_vm.t("views.about.content_top")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      { ref: "lastParagraph", staticClass: "t-paragraph" },
                      [_vm._v(_vm._s(_vm.t("views.about.content_bottom")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        ref: "officialLink",
                        staticClass:
                          "t-link t-btn t-btn--tertiary js-btn-circle",
                        attrs: {
                          href: "https://seashepherd.org/",
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "t-btn__panel__before" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "t-btn__label" }, [
                          _c("span", { staticClass: "t-btn__label__masked" }, [
                            _vm._v(_vm._s(_vm.t("views.common.cta_discover")))
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _c("span", { staticClass: "t-btn__panel" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "t-btn__label__circle" }, [
                          _c(
                            "span",
                            { staticClass: "t-btn__label__circle__masked" },
                            [_vm._v(_vm._s(_vm.t("views.common.cta_discover")))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "t-btn__panel__after" })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { ref: "aboutLine", staticClass: "c-About__line" },
                    [_vm._m(3)]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "c-About__link t-paragraph",
                  attrs: { "aria-hidden": "hidden" }
                },
                [_vm._v("No-fishing.net")]
              ),
              _vm._v(" "),
              _vm._m(4)
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "c-About__close__mobile t-btn t-btn--action--l",
              on: { click: _vm.closeAboutPanel }
            },
            [
              _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
                _vm._v(_vm._s(_vm.t("views.common.cta_close")))
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "t-btn__icon" },
                [
                  _c("ui-icon", {
                    attrs: { type: "cross-mobile", color: "dark" }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-About__bar" }, [
            _c("ul", { staticClass: "c-About__bar__inner t-list" }, [
              _vm._m(5),
              _vm._v(" "),
              _c("li", { staticClass: "c-About__bar__separator" }),
              _vm._v(" "),
              _c("li", { staticClass: "c-About__bar__item" }, [
                _vm.navigatorShare
                  ? _c(
                      "button",
                      {
                        staticClass: "c-About__bar__link t-link t-btn",
                        on: { click: _vm.onShare }
                      },
                      [_vm._v(_vm._s(_vm.t("views.common.menu_share")))]
                    )
                  : _c(
                      "button",
                      {
                        ref: "shareAbout",
                        staticClass: "c-About__bar__link t-link t-btn",
                        attrs: { "data-clipboard-text": _vm.clipText }
                      },
                      [_vm._v(_vm._s(_vm.t("views.common.menu_share")))]
                    )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "c-About__bar__separator" }),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "c-About__bar__item" },
                [
                  _vm.currentLocale === "en"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "c-About__bar__link t-btn",
                          attrs: {
                            to: { name: "game-1", params: { lang: "fr" } }
                          }
                        },
                        [
                          _c("span", [_vm._v("en")]),
                          _vm._v(" "),
                          _c("span", [_vm._v("/")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "is-not-currentLocale" }, [
                            _vm._v("fr")
                          ])
                        ]
                      )
                    : _vm.currentLocale === "fr"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "c-About__bar__link t-btn",
                          attrs: {
                            to: { name: "game-1", params: { lang: "en" } }
                          }
                        },
                        [
                          _c("span", { staticClass: "is-not-currentLocale" }, [
                            _vm._v("en")
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("/")]),
                          _vm._v(" "),
                          _c("span", [_vm._v("fr")])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "c-About__close-panel",
            on: { click: _vm.closeAboutPanel }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-About__player",
              class: { "is-active": _vm.videoActive },
              on: { click: _vm.closeVideoPanel }
            },
            [
              _c("div", { staticClass: "c-About__player__inner" }, [
                _c("div", {
                  ref: "playerYT",
                  staticClass: "c-About__player__youtube"
                })
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "closeVideoBtn",
                  staticClass:
                    "c-About__player__close t-btn t-btn--action--label--alt is-appear",
                  on: { click: _vm.closeVideoPanel }
                },
                [
                  _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
                    _vm._v(_vm._s(_vm.t("views.common.cta_close")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "t-btn__icon" },
                    [
                      _c("div", { staticClass: "t-btn__circle" }),
                      _vm._v(" "),
                      _c("ui-icon", {
                        attrs: { type: "cross", color: "light" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "t-btn__hover" }, [
                    _c("span", { staticClass: "t-btn__circle__hover" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "t-btn__icon__hover" },
                      [
                        _c("ui-icon", {
                          attrs: { type: "cross", color: "dark" }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "c-About__player__close__mobile t-btn t-btn--action--l",
                  on: { click: _vm.closeVideoPanel }
                },
                [
                  _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
                    _vm._v(_vm._s(_vm.t("views.common.cta_close")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "t-btn__icon" },
                    [
                      _c("ui-icon", {
                        attrs: { type: "cross-mobile", color: "light" }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.isMobileLandscape
        ? _c("div", { staticClass: "mobile-landscape" }, [
            _c("p", { staticClass: "t-text--xl" }, [
              _vm._v(_vm._s(_vm.t("views.common.mobile_landscape")))
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "t-btn__label" }, [
      _c("span", { staticClass: "t-btn__label__top" }, [
        _c("span", { staticClass: "t-btn__label__masked" }, [_vm._v("Play")])
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "t-btn__label__bottom" }, [
        _c("span", { staticClass: "t-btn__label__masked" }, [_vm._v("Film")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "t-btn__label__hover" }, [
      _c("span", { staticClass: "t-btn__label__hover__top" }, [
        _c("span", { staticClass: "t-btn__label__hover__masked" }, [
          _vm._v("Play")
        ])
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "t-btn__label__hover__bottom" }, [
        _c("span", { staticClass: "t-btn__label__hover__masked" }, [
          _vm._v("Film")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "t-btn__circle__wrapper" }, [
      _c("span", { staticClass: "t-btn__circle js-btn-circle-el" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-About__line__inner" }, [
      _c("div", { staticClass: "c-About__line__masked" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-About__line" }, [
      _c("div", { staticClass: "c-About__line__inner" }, [
        _c("div", { staticClass: "c-About__line__masked" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "c-About__bar__item" }, [
      _c(
        "a",
        {
          staticClass: "c-About__bar__link t-link",
          attrs: { href: "https://seashepherd.org/", target: "_blank" }
        },
        [_vm._v("Official site")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }