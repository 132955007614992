import { seconds } from "lol/js/promise/time";
import lottie from 'lottie-web';
import crossAnimationData from 'assets/bodymovin/bodymovin_cross.json';
import arrowAnimationData from 'assets/bodymovin/bodymovin_arrow.json';
import tridentAnimationData from 'assets/bodymovin/bodymovin_trident.json';
import UIIcon from "components/ui-icon/ui-icon.vue";
import { SectionMixin } from "mixins/SectionMixin";
import { ImageResource } from "core/resource/ImageResource";
import BrowserDetect from 'vendors/BrowserDetect';
import IntroDrag from "utils/IntroDrag";
const INTERVAL_TIME = 4000;
export default {
  mixins: [SectionMixin],

  data() {
    return {
      activeNumberIndex: 0,
      isDragging: false,
      numbers: []
    };
  },

  components: {
    "ui-icon": UIIcon
  },

  created() {
    this.getNumbers();
  },

  beforeDestroy() {
    this.stopNumbers();
  },

  mounted() {
    // this.showDebugCanvas()
    this.introDrag = new IntroDrag({
      container: this.$refs.introDragContainer,
      dragElement: this.$refs.introDragable,
      endPoint: this.$refs.endPoint,
      dragEase: .1,
      dragAngle: .7,
      elipseHeightRatio: .1,
      completeThreshold: .7,
      onStart: this.onDragStart,
      onEnd: this.onDragEnd,
      onProgressChange: this.onDragProgressChange,
      onComplete: this.onCompleteDrag
    }); // Load cross Animation

    this.crossAnimation = lottie.loadAnimation({
      animationData: crossAnimationData,
      container: this.$refs.crossContainer,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    }); // Load dragable Animation

    this.arrowAnimation = lottie.loadAnimation({
      animationData: arrowAnimationData,
      container: this.$refs.introDragable,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    }); // Load trident Animation

    this.tridentAnimation = lottie.loadAnimation({
      animationData: tridentAnimationData,
      container: this.$refs.introDragable,
      renderer: 'canvas',
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: 'none'
      },
      loop: false,
      autoplay: false
    });
  },

  methods: {
    /////////////
    // Components methods
    /////////////
    enterComponent() {
      this.setNumbersHeight(); // Drag

      this.activeDrag(); // Events

      this.bindEvents(); // Animation

      this.enterAnimation();
    },

    leaveComponent() {
      // Drag
      this.unactiveDrag(); // Events

      this.unbindEvents(); // Animation

      this.leaveAnimation();
    },

    async enterAnimation() {
      await seconds(this.$appdatas.store.fadeViewDuration);
      this.$refs.numbersDatas.classList.add('is-appear');
      this.$refs.instructions.classList.add('is-appear');
      this.$refs.introDragContainer.classList.add('is-appear');
      this.$refs.introDragDot.classList.add('is-appear');
      this.crossAnimation.play();
      await seconds(2.5);
      this.arrowAnimation.play();
      this.$refs.introDragContainer.classList.add('is-ready');
      this.$refs.numbersDatas.classList.add('is-animated');
      this.playNumbers();
    },

    async leaveAnimation() {
      this.$refs.numbersDatas.classList.remove('is-appear');
      this.$refs.instructions.classList.remove('is-appear');
      this.$refs.introDragContainer.classList.remove('is-appear');
      this.$refs.introDragContainer.classList.remove('is-ready');
      this.$refs.numbersDatas.classList.remove('is-animated');
      this.$refs.introDragDot.classList.remove('is-appear');
      setTimeout(() => {
        this.crossAnimation.goToAndStop(0);
        this.arrowAnimation.goToAndStop(0);
        this.tridentAnimation.goToAndStop(0);
      }, this.$appdatas.store.fadeViewDuration);
      this.stopNumbers();
    },

    /////////////
    // Get datas
    /////////////
    getNumbers() {
      const datas = this.t('datas');
      const numbers = datas.numbers;
      this.numbers = Object.values(numbers);
    },

    /////////////
    // Events methods
    /////////////
    bindEvents() {
      this.unwatchWidth = this.$watch('$appdatas.store.screen.width', function () {
        this.onResize();
      });
      this.unwatchHeight = this.$watch('$appdatas.store.screen.height', function () {
        this.onResize();
      });
    },

    unbindEvents() {
      // Unwatch
      // https://codingexplained.com/coding/front-end/vue-js/adding-removing-watchers-dynamically
      this.unwatchWidth();
      this.unwatchHeight();
    },

    onResize() {
      // Numbers carousel
      this.setNumbersHeight(); // Drag methods

      this.introDrag && this.introDrag.resize();
    },

    /////////////
    // Intro drag methods
    /////////////
    activeDrag() {
      this.introDrag && this.introDrag.bindEvents();
    },

    unactiveDrag() {
      this.introDrag && this.introDrag.unbindEvents();
    },

    onDragStart() {
      this.isDragging = true;
      this.tridentAnimation.goToAndPlay(0);
    },

    onDragEnd() {
      this.isDragging = false;
      this.arrowAnimation.goToAndPlay(0);
    },

    onDragProgressChange(value) {
      this.$appdatas.store.introDragProgress = value;
    },

    onCompleteDrag() {
      this.$emit('nextView');
    },

    showDebugCanvas() {
      const cvs = this.$el.querySelector('#debugcanvas');
      cvs.width = 520;
      cvs.height = 520;
      const ctx = cvs.getContext('2d');
      const startAngle = Math.PI;
      const endAngle = 0;
      const rx = 260;
      const ry = cvs.height * 0.1;
      const center = 260;
      let angle = 0;
      ctx.fillStyle = 'red';

      for (let angle = startAngle; angle > endAngle; angle -= 0.02) {
        ctx.beginPath();
        const x = center + Math.cos(angle) * rx;
        const y = center + Math.sin(angle) * ry;
        ctx.arc(x, y, 2, 0, Math.PI * 2);
        ctx.fill();
        ctx.closePath();
      }

      let progress = 0;
      const currentAngle = startAngle - (startAngle - endAngle) * progress;
      ctx.fillStyle = 'green';
      ctx.beginPath();
      const x = center + Math.cos(currentAngle) * rx;
      const y = center + Math.sin(currentAngle) * ry;
      ctx.arc(x, y, 10, 0, Math.PI * 2);
      ctx.fill();
      ctx.closePath();
    },

    /////////////
    // Numbers methods
    /////////////
    playNumbers() {
      let index = 0;
      this.interval = setInterval(() => {
        index++;
        this.activeNumberIndex = index % this.numbers.length;
      }, INTERVAL_TIME);
    },

    stopNumbers() {
      this.interval && clearInterval(this.interval);
    },

    setNumbersHeight() {
      let i = 0;
      const $items = document.querySelectorAll('.js-data-item');
      const heights = [];

      while (i < $items.length) {
        const height = $items[i].offsetHeight;
        heights.push(height);
        i++;
      }

      const translateValue = Math.max(...heights) * -1 / 2;
      this.$refs.datasInner.style.transform = `translate3d(0,${translateValue}px,0)`;
    }

  }
};