import anime from 'animejs';
import ClipboardJS from 'clipboard';
import lottie from 'lottie-web';
import { seconds } from "lol/js/promise/time";
import Module from "modules/layout/LayoutModule";
import { ButtonMixin } from "mixins/ButtonMixin";
import ModuleComponent from "mixins/ModuleComponent";
import { registerGLApp } from "mixins/GLAppProvider";
import { VIEWS } from "datas";
import YouTubeIframe from "utils/YouTubeIframe";
import Preloader from "sections/preloader/preloader.vue";
import Introduction from "sections/introduction/introduction.vue";
import Homepage from "sections/homepage/homepage.vue";
import Donation from "sections/donation/donation.vue";
import UIIcon from "components/ui-icon/ui-icon.vue";
import ObjectFit from "components/object-fit/object-fit.vue";
import crossAnimationData from 'assets/bodymovin/bodymovin_cross_black.json';

const _module = ModuleComponent(Module);

export default {
  _module: _module,
  mixins: [_module, ButtonMixin],
  name: 'layout',

  data() {
    return {
      isLoaded: false,
      isAnimating: false,
      isMobileLandscape: false,
      aboutActive: false,
      videoActive: false,
      copiedText: false,
      wasMuted: false,
      views: VIEWS,
      covers: {},
      thumbnails: {},
      logoImg: {},
      aboutImage: {},
      navigatorShare: navigator && navigator.share ? true : false
    };
  },

  components: {
    Preloader,
    Introduction,
    Homepage,
    Donation,
    ObjectFit,
    "ui-icon": UIIcon
  },
  computed: {
    clipText() {
      return document.location.href;
    }

  },
  watch: {
    '$appdatas.store.currentView': function (value, oldValue) {
      this.$refs[oldValue] && this.$refs[oldValue].leaveComponent();
      this.$refs[value] && this.$refs[value].enterComponent();
    }
  },

  mounted() {
    this.youtubePlayer = new YouTubeIframe({
      element: this.$refs.playerYT,
      videoId: 'Mbo0NEBEv-w',
      onEnded: this.closeVideoPanel
    });

    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      document.body.classList.add('safari');
    }

    this.onResize();
  },

  methods: {
    bindEvents() {
      this.animCopyText = this.animCopyText.bind(this);
      this.onVisibilityChange = this.onVisibilityChange.bind(this);

      if (!this.navigatorShare) {
        this.clipboard.on('success', this.animCopyText);
        this.clipboardAbout.on('success', this.animCopyText);
      } // On visibility change


      document.addEventListener("visibilitychange", this.onVisibilityChange);
      this.$watch('$appdatas.store.screen.width', function () {
        this.onResize();
      });
    },

    async setView(view) {
      await this.fadeTransition(view);
      this.$appdatas.store.currentView = view;
      this.isAnimating = false; // if (view === this.views.Introduction) {
      //  this.$appdatas.audioLayout.play('MUSIC', 1, true);
      // }
    },

    onResourceLoaded(resource) {
      this.loadCount += 1;

      if (this.loadCount == this.resCount) {
        this.hide();
      }
    },

    onLoaded() {
      this.isLoaded = true;
      this.$nextTick(() => {
        this.$refs[VIEWS.Preloader].watchResource(this.getModule().resources); // Set logo image

        this.logoImg = this.getResource('logo'); // Launch enter component

        const currentViewComponent = this.$refs[this.$appdatas.store.currentView];
        currentViewComponent && currentViewComponent.enterComponent && currentViewComponent.enterComponent(); // Set clipboard

        if (!this.navigatorShare) {
          this.clipboard = new ClipboardJS(this.$refs.share);
          this.clipboardAbout = new ClipboardJS(this.$refs.shareAbout);
        }

        this.bindEvents(); // Set ship covers

        this.setCovers(); // Set ship thumbnails

        this.setThumbnails(); // Set about icon

        this.crossAnimation = lottie.loadAnimation({
          animationData: crossAnimationData,
          container: this.$refs.crossContainer,
          renderer: 'canvas',
          rendererSettings: {
            clearCanvas: true,
            preserveAspectRatio: 'none'
          },
          loop: false,
          autoplay: false
        }); // Set about image

        this.aboutImage = this.getResource('bob_barker');
        this.$nextTick(() => {
          this.$refs.visual && this.$refs.visual.resize();
        });
      });
    },

    onShare() {
      if (this.navigatorShare) {
        window.navigator.share({
          title: this.t('views.common.share_api_title'),
          url: document.location.href,
          text: this.t('views.common.share_api_text')
        }).catch(console.error);
      }
    },

    onResize() {
      if (this.$appdatas.store.isTouchable && window.innerWidth > window.innerHeight && window.innerWidth < 1024) {
        this.isMobileLandscape = true;
      } else {
        this.isMobileLandscape = false;
      }
    },

    async fadeTransition(view) {
      if (this.$appdatas.store.currentView == 'introduction' && view == 'homepage') {
        return await seconds(0);
      } else {
        this.isAnimating = true;
        return await seconds(this.$appdatas.store.fadeViewDuration);
      }
    },

    resetViews() {
      this.$refs[this.views.Preloader].reset();
      this.setView(this.views.Preloader);
    },

    setCovers() {
      this.covers = {
        "bob_barker": this.getResource('bob_barker'),
        "brigitte_bardot": this.getResource('brigitte_bardot'),
        "farley_mowat": this.getResource('farley_mowat'),
        "jairo_mora_sandoval": this.getResource('jairo_mora_sandoval'),
        "john_paul_dejoria": this.getResource('john_paul_dejoria'),
        "martin_sheen": this.getResource('martin_sheen'),
        "ocean_warrior": this.getResource('ocean_warrior'),
        "sam_simon": this.getResource('sam_simon'),
        "white_holly": this.getResource('white_holly')
      };
    },

    setThumbnails() {
      this.thumbnails = {
        "bob_barker": this.getResource('bob_barker_thumbnail'),
        "brigitte_bardot": this.getResource('brigitte_bardot_thumbnail'),
        "farley_mowat": this.getResource('farley_mowat_thumbnail'),
        "jairo_mora_sandoval": this.getResource('jairo_mora_sandoval_thumbnail'),
        "john_paul_dejoria": this.getResource('john_paul_dejoria_thumbnail'),
        "martin_sheen": this.getResource('martin_sheen_thumbnail'),
        "ocean_warrior": this.getResource('ocean_warrior_thumbnail'),
        "sam_simon": this.getResource('sam_simon_thumbnail'),
        "white_holly": this.getResource('white_holly_thumbnail')
      };
    },

    toggleSound() {// this.$appdatas.store.soundMuted = !this.$appdatas.store.soundMuted      
      // const volume = this.$appdatas.store.soundMuted ? 0.0 : 1.0;
      // const volumeObj = { value: this.$appdatas.store.soundMuted ? 1.0 : 0.0 };
      // const volumeTween = anime({
      //   targets: volumeObj,
      //   value: volume,
      //   duration: 1000,
      //   easing: 'linear',
      //   update: () => {
      //     this.getModule().setVolume(volumeObj.value);
      //   }
      // })      
    },

    async openAboutPanel() {
      this.aboutActive = true;
      this.resizeAbout();
      this.unwatchAboutWidth = this.$watch('$appdatas.store.screen.width', function () {
        this.resizeAbout();
      });
      this.unwatchAboutHeight = this.$watch('$appdatas.store.screen.height', function () {
        this.resizeAbout();
      });
      await seconds(1.666);
      this.$refs.closeBtn.classList.add('is-appear');
      this.crossAnimation.goToAndPlay(0);
      await seconds(0.333);
      this.$refs.playFilmBtn.classList.add('is-appear');
      this.$refs.officialLink.classList.add('is-appear');
      this.$appdatas.store.popinOpen = true;
    },

    closeAboutPanel() {
      this.aboutActive = false;
      this.resetAnimations();
      this.$appdatas.store.popinOpen = false;
      this.unwatchAboutWidth();
      this.unwatchAboutHeight();
    },

    resizeAbout() {
      this.$refs.aboutLine.style.marginBottom = `${this.$refs.lastParagraph.offsetHeight}px`;
    },

    openVideoPanel() {
      if (this.videoActive) return;
      this.videoActive = true;
      this.wasMuted = this.$appdatas.store.soundMuted;
      if (!this.wasMuted) this.toggleSound();
      this.youtubePlayer && this.youtubePlayer.play();
      this.$refs.closeVideoBtn.classList.add('is-appear');
    },

    closeVideoPanel() {
      if (!this.videoActive) return;
      this.videoActive = false;
      if (!this.wasMuted) this.toggleSound();
      this.youtubePlayer && this.youtubePlayer.stop();
      this.$refs.closeVideoBtn.classList.remove('is-appear');
    },

    async resetAnimations() {
      await seconds(0.666);
      this.$refs.closeBtn.classList.remove('is-appear');
      this.$refs.playFilmBtn.classList.remove('is-appear');
      this.$refs.officialLink.classList.remove('is-appear');
      this.crossAnimation.goToAndStop(0);
    },

    async animCopyText() {
      this.copiedText = true;
      await seconds(2);
      this.copiedText = false;
    },

    onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        if (this.$appdatas.store.currentView === this.views.Preloader) {
          this.$refs[this.views.Preloader].visibilityPlayVideo();
        } else if (this.$appdatas.store.currentView != this.views.Preloader) {
          document.removeEventListener('click', this.restartSound);
          document.addEventListener('click', this.restartSound);
        }
      } else {
        this.$refs[this.views.Preloader].visibilityPauseVideo(); // this.$appdatas.audioLayout.forceDispose()
      }
    },

    restartSound() {
      // this.$appdatas.audioLayout.play('MUSIC', 1, true);
      document.removeEventListener('click', this.restartSound);
    }

  }
};