// Imports
import Vue from "vue";
import { I18n } from 'core/I18n';
const videoWidth = 1920;
const videoHeight = 1080;
const videoMobileWidth = 374;
const videoMobileHeight = 664;
const consts = {
  BIND_CUES: 'bind_cues',
  UNBIND_CUES: 'unbind_cues'
};
export default Vue.extend({
  data() {
    return {
      suffix: 'mobile',
      subtitlesText: '',
      subtitlesEnabled: true
    };
  },

  created() {
    //this.suffix = window.innerWidth < 768 ? 'mobile' : 'desktop';
    this.suffix = 'desktop';
    this.videoWidth = this.suffix === 'mobile' ? videoMobileWidth : videoWidth;
    this.videoHeight = this.suffix === 'mobile' ? videoMobileHeight : videoHeight;
  },

  mounted() {
    this.initSubtitles();
    this.bindEvents();
    this.onResize();
  },

  beforeDestroy() {
    this.unbindEvents();
  },

  methods: {
    enter() {
      this.updateSubtitles();
      this.unwatchCurrentLocale = this.$watch('currentLocale', function () {
        this.updateSubtitles();
      });
    },

    leave() {
      this.unwatchCurrentLocale();
    },

    bindEvents() {
      if (this.$appdatas.store.isIOS) {
        this.$refs.video.addEventListener('loadedmetadata', this.onReadyToPlay, false);
      } else {
        this.$refs.video.addEventListener('canplay', this.onReadyToPlay, false);
      }

      this.$refs.video.addEventListener("ended", this.onEnded);
      this.unwatchWidth = this.$watch('$appdatas.store.screen.width', function () {
        this.onResize();
      });
      this.unwatchHeight = this.$watch('$appdatas.store.screen.height', function () {
        this.onResize();
      });
      this.unwatchCurrentLocale = this.$watch('currentLocale', function () {
        this.updateSubtitles();
      });
    },

    unbindEvents() {
      if (this.$appdatas.store.isIOS) {
        this.$refs.video.removeEventListener('loadedmetadata', this.onReadyToPlay, false);
      } else {
        this.$refs.video.removeEventListener('canplay', this.onReadyToPlay, false);
      }

      this.$refs.video.removeEventListener("ended", this.onEnded); // Unwatch
      // https://codingexplained.com/coding/front-end/vue-js/adding-removing-watchers-dynamically

      this.unwatchWidth();
      this.unwatchHeight();
      this.unwatchCurrentLocale();
      if (this.track) this.bindOrUnbindCues(this.track, consts.UNBIND_CUES);
    },

    calculateAspectRatio(srcWidth, srcHeight, maxWidth, maxHeight, fit) {
      let ratio = [maxWidth / srcWidth, maxHeight / srcHeight];
      let temp = fit ? Math.min(ratio[0], ratio[1]) : Math.max(ratio[0], ratio[1]);
      return {
        width: srcWidth * temp,
        height: srcHeight * temp
      };
    },

    onResize() {
      const $el = this.$refs.video;
      const parentWidth = this.$el.clientWidth;
      const parentHeight = this.$el.clientHeight;
      const computedSize = this.calculateAspectRatio(this.videoWidth, this.videoHeight, parentWidth, parentHeight, false);
      let offsetX = Math.round((parentWidth - computedSize.width) * 0.5);
      let offsetY = Math.round((parentHeight - computedSize.height) * 0.5);

      if (parentHeight === 0) {
        offsetY = 0;
      } // Apply styles


      $el.style.width = `${Math.round(computedSize.width)}px`;
      $el.style.height = `${Math.round(computedSize.height)}px`;
      $el.style.transform = `translate3d(${offsetX}px, ${offsetY}px,0)`;
    },

    onReadyToPlay() {
      this.$emit("videoReady");
    },

    onEnded() {
      this.$emit("videoEnded");
    },

    updateSubtitles() {
      const tracks = this.$refs.video.textTracks;
      this.setTrack(tracks);
    },

    initSubtitles() {
      const tracks = this.$refs.video.textTracks;
      if (!tracks) return;

      const onTrackAdded = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setTrack(tracks);
          tracks.removeEventListener('addtrack', onTrackAdded);
        }, 1000);
      };

      tracks.addEventListener('addtrack', onTrackAdded);
    },

    setTrack(tracks) {
      if (!tracks) return;

      if (this.track) {
        this.bindOrUnbindCues(this.track, consts.UNBIND_CUES);
        this.track = undefined;
      }

      for (let index = 0; index < tracks.length; index++) {
        const track = tracks[index];

        if (track.language === this.currentLocale) {
          this.track = track;
          break;
        }
      }

      this.track.mode = 'hidden';
      setTimeout(() => {
        this.bindOrUnbindCues(this.track, consts.BIND_CUES);
      }, 100);
    },

    bindOrUnbindCues(track, bindOrUnbind) {
      if (!track) return;
      if (this.subtitlesBinded && bindOrUnbind === consts.BIND_CUES || !this.subtitlesBinded && bindOrUnbind === consts.UNBIND_CUES) return;
      this.subtitlesBinded = bindOrUnbind === consts.BIND_CUES;
      const cues = track.cues;

      for (let i = 0, l = cues.length; i < l; i++) {
        const cue = cues[i];
        const type = bindOrUnbind === consts.BIND_CUES ? 'add' : 'remove';
        const method = `${type}EventListener`;
        cue[method]('enter', this.onCueEnter, false);
        cue[method]('exit', this.onCueExit, false);
      }
    },

    onCueEnter(event) {
      const target = event.currentTarget || event.target;
      this.subtitlesText = target.text;
      this.toggleSubtitles(true);
    },

    onCueExit(event) {
      this.toggleSubtitles(false);
    },

    toggleSubtitles(toggle) {
      this.subtitlesEnabled = toggle;
    }

  }
});