var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-Introduction" }, [
    _c("div", { staticClass: "t-grid t-grid--default" }, [
      _c(
        "div",
        {
          staticClass:
            "c-Introduction__inner t-grid-start-2 t-grid-end-16 t-grid-start-1--md t-grid-end-7--md"
        },
        [
          _c("div", { ref: "numbersDatas", staticClass: "c-Datas" }, [
            _c("div", { ref: "datasInner", staticClass: "c-Datas__inner" }, [
              _c(
                "ul",
                { staticClass: "c-Datas__list t-list" },
                _vm._l(_vm.numbers, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "c-Datas__item js-data-item",
                      class: { "is-active": index === _vm.activeNumberIndex }
                    },
                    [
                      _c("p", { staticClass: "c-Datas__content" }, [
                        _c("span", { staticClass: "c-Datas__number" }, [
                          _vm._v(_vm._s(item.number))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "c-Datas__text" }, [
                          _vm._v(_vm._s(item.text))
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-Datas__line" },
                [
                  _c("ui-icon", {
                    staticClass:
                      "c-Datas__line__join c-Datas__line__join--left",
                    attrs: { type: "join", color: "light" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-Datas__line__trait" }),
                  _vm._v(" "),
                  _c("ui-icon", {
                    staticClass:
                      "c-Datas__line__join c-Datas__line__join--right",
                    attrs: { type: "join", color: "light" }
                  })
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "c-Introduction__inner t-grid-start-2 t-grid-end-16 t-grid-start-15--md t-grid-end-21--md"
        },
        [
          _c(
            "div",
            {
              ref: "instructions",
              staticClass: "c-Introduction__instructions"
            },
            [
              _c("div", {
                ref: "crossContainer",
                staticClass: "c-Introduction__instructions__icon"
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "c-Introduction__instructions__text t-text--xl"
                },
                [_vm._v(_vm._s(_vm.t("views.introduction.instructions")))]
              )
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-Introduction__intro-drag" }, [
      _c(
        "div",
        {
          ref: "introDragContainer",
          staticClass: "c-Introduction__intro-drag__container"
        },
        [
          _c("div", {
            ref: "introDragable",
            staticClass:
              "t-btn t-btn--action--l c-Introduction__intro-drag__draggable"
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "endPoint",
              staticClass: "c-Introduction__intro-drag__endPoint"
            },
            [
              _c(
                "div",
                { staticClass: "c-Introduction__intro-drag__endPoint__offset" },
                [
                  _c("div", {
                    ref: "introDragDot",
                    staticClass: "c-Introduction__intro-drag__endPoint__dot"
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("canvas", { attrs: { id: "debugcanvas" } })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }