import VirtualScroll from 'virtual-scroll';
import { RAF } from 'lol/js/dom/raf';
export default class InfiniteScroller {
    constructor(opts) {
        this.slides = [];
        this.opts = opts;
        this.setStates(this.opts.container);
        this.setSlides(this.opts.slides);
        this.virtualScroll = new VirtualScroll({
            el: this.opts.scroller,
            mouseMultiplier: navigator.platform.indexOf('Win') > -1 ? 1 : 0.4,
            firefoxMultiplier: 120,
            useKeyboard: false,
            passive: true
        });
    }
    bindEvents() {
        this.handleScroll = this.handleScroll.bind(this);
        this.getTranslateValue = this.getTranslateValue.bind(this);
        this.update = this.update.bind(this);
        this.virtualScroll.on(this.handleScroll);
        this.resetTranslateValue();
        RAF.subscribe('infiniteScroll', this.update);
    }
    unbindEvents() {
        this.virtualScroll.off(this.handleScroll);
        RAF.unsubscribe('infiniteScroll');
    }
    setStates($container) {
        this.states = {
            isReady: false,
            containerHeight: $container.offsetHeight,
            wHeight: window.innerHeight,
            translateValue: 0,
            currentTranslateValue: 0
        };
    }
    setSlides($slides) {
        let index = 0;
        // Loop on slides
        while (index < $slides.length) {
            // Get slide
            const $slide = $slides[index];
            // Get metrics
            const { top, bottom, height } = $slide.getBoundingClientRect();
            // Get visible state
            const visible = top < this.states.wHeight ? true : false;
            // Create Slide
            const slide = {
                el: $slide,
                index,
                top,
                bottom,
                height,
                visible
            };
            // Store Slide
            this.slides.push(slide);
            // Increment
            index++;
        }
    }
    resetTranslateValue() {
        const centeredValue = this.states.containerHeight - (this.states.wHeight / 2) + (this.slides[0].height / 2);
        this.states.translateValue = this.states.currentTranslateValue = centeredValue;
        this.opts.scroller.style.transform = `translate3d(0,${this.states.translateValue}px,0)`;
    }
    handleScroll(e) {
        if (e.originalEvent.type === 'wheel') {
            this.states.translateValue += ((e.deltaY * -1) * this.opts.friction);
        }
    }
    getTranslateValue() {
        this.states.currentTranslateValue += (this.states.translateValue - this.states.currentTranslateValue) * this.opts.ease;
        // MODULO BUG : https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
        const moduloValue = ((this.states.currentTranslateValue % this.states.containerHeight) + this.states.containerHeight) % this.states.containerHeight;
        return moduloValue * -1;
    }
    resize() {
        this.states.containerHeight = this.opts.container.offsetHeight,
            this.states.wHeight = window.innerHeight;
    }
    update() {
        const translateValue = this.getTranslateValue();
        this.opts.scroller.style.transform = `translate3d(0,${translateValue}px,0)`;
    }
}
