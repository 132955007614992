var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-Preloader", class: { "is-ready": _vm.isReady } },
    [
      _c(
        "div",
        { staticClass: "c-Preloader__lang" },
        [
          _vm.currentLocale === "en"
            ? _c(
                "router-link",
                {
                  staticClass: "c-Preloader__lang__link t-btn",
                  attrs: { to: { name: "game-1", params: { lang: "fr" } } }
                },
                [
                  _c("span", [_vm._v("en")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("/")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "is-not-currentLocale" }, [
                    _vm._v("fr")
                  ])
                ]
              )
            : _vm.currentLocale === "fr"
            ? _c(
                "router-link",
                {
                  staticClass: "c-Preloader__lang__link t-btn",
                  attrs: { to: { name: "game-1", params: { lang: "en" } } }
                },
                [
                  _c("span", { staticClass: "is-not-currentLocale" }, [
                    _vm._v("en")
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("/")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("fr")])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-Preloader__intro c-Preloader__section",
          class: { "is-current": _vm.activeSectionIndex === 0 }
        },
        [
          _c("div", { staticClass: "t-grid t-grid--default" }, [
            _c(
              "div",
              { staticClass: "t-grid-start-2 t-grid-end-16 t-grid-end-20--md" },
              [
                _c("div", { staticClass: "c-Preloader__intro__inner" }, [
                  _c(
                    "div",
                    {
                      ref: "introLogo",
                      staticClass: "c-Preloader__intro__logo"
                    },
                    [
                      _c("ui-icon", { attrs: { type: "logo", color: "light" } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "introContent",
                      staticClass: "c-Preloader__intro__content"
                    },
                    [
                      _c("div", { staticClass: "c-Preloader__baseline" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "c-Preloader__baseline__text t-text--xl"
                          },
                          [_vm._v(_vm._s(_vm.t("views.preloader.title")))]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          ref: "crossContainer",
                          staticClass: "c-Preloader__baseline__icon"
                        }),
                        _vm._v(" "),
                        _c(
                          "h2",
                          { staticClass: "c-Preloader__baseline__title t-h4" },
                          [_vm._v(_vm._s(_vm.t("views.preloader.baseline")))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "c-Preloader__intro__loader" }, [
                        _c(
                          "div",
                          {
                            staticClass: "c-Preloader__loader",
                            class: { "is-appear": _vm.isLoadingBarReady }
                          },
                          [
                            _c("ui-icon", {
                              staticClass: "c-Preloader__loader__join",
                              attrs: { type: "join", color: "light" }
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "c-Preloader__loader__line",
                              style: {
                                transform:
                                  "scaleX(" + _vm.loaderProgress / 100 + ")"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "c-Preloader__loader__trident",
                                style: {
                                  transform:
                                    "translate3d(" +
                                    _vm.loaderProgress +
                                    "%, 0, 0)"
                                }
                              },
                              [
                                _c("ui-icon", {
                                  staticClass: "c-Preloader__loader__icon",
                                  attrs: { type: "trident", color: "light" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            ref: "discoverButton",
                            staticClass: "t-btn t-btn--primary js-btn-circle",
                            on: { click: _vm.playVideo }
                          },
                          [
                            _c("span", { staticClass: "t-btn__panel__before" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "t-btn__label" }, [
                              _c(
                                "span",
                                { staticClass: "t-btn__label__masked" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.t("views.common.cta_discover"))
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm._m(0),
                            _vm._v(" "),
                            _c("span", { staticClass: "t-btn__panel" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "t-btn__label__circle" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "t-btn__label__circle__masked"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.t("views.common.cta_discover"))
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "t-btn__panel__after" })
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "object-fit",
            {
              ref: "visual",
              staticClass: "c-Preloader__visual",
              attrs: {
                width: _vm.introductionImg.width,
                height: _vm.introductionImg.height
              }
            },
            [
              _c("img", {
                staticClass: "c-Preloader__visual__img js-object-fit",
                attrs: { src: _vm.introductionImg.src }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-Preloader__video c-Preloader__section",
          class: { "is-current": _vm.activeSectionIndex === 1 }
        },
        [
          _c("main-video", {
            ref: "video",
            on: { videoReady: _vm.onVideoLoaded, videoEnded: _vm.stopVideo }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "c-Preloader__video__button" }, [
            _c(
              "button",
              {
                ref: "skipButton",
                staticClass: "t-btn t-btn--secondary js-btn-circle",
                on: { click: _vm.stopVideo }
              },
              [
                _c("span", { staticClass: "t-btn__panel__before" }),
                _vm._v(" "),
                _c("span", { staticClass: "t-btn__label" }, [
                  _c("span", { staticClass: "t-btn__label__masked" }, [
                    _vm._v(_vm._s(_vm.t("views.common.cta_skip")))
                  ])
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("span", { staticClass: "t-btn__panel" }),
                _vm._v(" "),
                _c("span", { staticClass: "t-btn__label__circle" }, [
                  _c("span", { staticClass: "t-btn__label__circle__masked" }, [
                    _vm._v(_vm._s(_vm.t("views.common.cta_skip")))
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "t-btn__panel__after" })
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "t-btn__circle__wrapper" }, [
      _c("span", { staticClass: "t-btn__circle js-btn-circle-el" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "t-btn__circle__wrapper" }, [
      _c("span", { staticClass: "t-btn__circle js-btn-circle-el" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }