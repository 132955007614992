import YouTubePlayer from 'youtube-player';
const stateNames = {
    '-1': 'unstarted',
    0: 'ended',
    1: 'playing',
    2: 'paused',
    3: 'buffering',
    5: 'video cued'
};
export default class YouTubeIframe {
    constructor(opts) {
        this.isPlaying = false;
        this.opts = opts;
        this.player = YouTubePlayer(opts.element, {
            videoId: opts.videoId,
            playerVars: { rel: 0, showinfo: 0 }
        });
        this.bindEvents();
    }
    bindEvents() {
        this.onPlayerReady = this.onPlayerReady.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.player.on('ready', this.onPlayerReady);
        this.player.on('stateChange', this.onStateChange);
    }
    unbindEvents() {
        this.player.off('ready', this.onPlayerReady);
        this.player.off('stateChange', this.onStateChange);
    }
    onStateChange(event) {
        if (!stateNames[event.data]) {
            throw new Error('Unknown state (' + event.data + ').');
        }
        switch (stateNames[event.data]) {
            case 'ended':
                this.onEnded();
                break;
            case 'playing':
                this.onPlaying();
                break;
            case 'paused':
                this.onPaused();
                break;
            case 'buffering':
                this.onBuffering();
                break;
        }
    }
    onPlayerReady() {
        this.opts.onPlayerReady && this.opts.onPlayerReady();
    }
    onEnded() {
        //console.log('onEnded')
        this.isPlaying = false;
        this.opts.onEnded && this.opts.onEnded();
    }
    onPlaying() {
        //console.log('onPlaying')
        this.isPlaying = true;
        this.opts.onPlaying && this.opts.onPlaying();
    }
    onPaused() {
        //console.log('onPaused')
        this.isPlaying = false;
        this.opts.onPaused && this.opts.onPaused();
    }
    onBuffering() {
        //console.log('onBuffering')
        this.opts.onBuffering && this.opts.onBuffering();
    }
    play(cb) {
        this.player.playVideo().then(cb);
    }
    pause(cb) {
        this.player.pauseVideo().then(cb);
    }
    stop(cb) {
        this.player.stopVideo().then(cb);
    }
}
