var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "c-Ship t-article", class: { "is-active": _vm.isActive } },
    [
      _c("div", { staticClass: "c-Ship__inner" }, [
        _c("div", { staticClass: "c-Ship__masked" }, [
          _c("header", { staticClass: "c-Ship__header t-header" }, [
            _c("div", { staticClass: "c-Ship__header__content" }, [
              _c(
                "button",
                {
                  ref: "closeBtn",
                  staticClass: "c-Ship__close t-btn t-btn--action--label--dark",
                  on: { click: _vm.close }
                },
                [
                  _c("span", { staticClass: "t-btn__label" }, [
                    _vm._v(_vm._s(_vm.t("views.common.cta_close")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "t-btn__icon" },
                    [
                      _c("div", { staticClass: "t-btn__circle" }),
                      _vm._v(" "),
                      _c("ui-icon", {
                        attrs: { type: "cross", color: "light" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "t-btn__hover" }, [
                    _c("span", { staticClass: "t-btn__circle__hover" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "t-btn__icon__hover" },
                      [
                        _c("ui-icon", {
                          attrs: { type: "cross", color: "light" }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-Ship__close__mobile t-btn t-btn--action--l",
                  on: { click: _vm.close }
                },
                [
                  _c("span", { staticClass: "t-btn__label hide-for-viewer" }, [
                    _vm._v(_vm._s(_vm.t("views.common.cta_close")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "t-btn__icon" },
                    [
                      _c("ui-icon", {
                        attrs: { type: "cross-mobile", color: "dark" }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "c-Ship__header__visual" }, [
                _c(
                  "div",
                  { staticClass: "c-Ship__header__visual__masked" },
                  [
                    _vm.image
                      ? _c(
                          "object-fit",
                          {
                            ref: "visual",
                            staticClass: "c-Ship__header__picture",
                            attrs: {
                              width: _vm.image.width,
                              height: _vm.image.height
                            }
                          },
                          [
                            _c("img", {
                              staticClass:
                                "c-Ship__header__picture__img js-object-fit",
                              attrs: { src: _vm.image.src }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "c-Ship__header__location",
                domProps: { innerHTML: _vm._s(_vm.ship.location) }
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "c-Ship__header__heading t-h2" }, [
                _c("span", { staticClass: "c-Ship__header__heading__prefix" }, [
                  _vm._v(_vm._s(_vm.ship.prefix))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "c-Ship__header__heading__name" }, [
                  _vm._v(
                    _vm._s(_vm.ship.firstName) + " " + _vm._s(_vm.ship.lastName)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "c-Ship__header__datas t-list" }, [
                _c(
                  "li",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.t("views.ship.launched")))]),
                    _vm._v(" "),
                    _c("ui-icon", { attrs: { type: "arrow", color: "dark" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.ship.launched))])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.t("views.ship.type")))]),
                    _vm._v(" "),
                    _c("ui-icon", { attrs: { type: "arrow", color: "dark" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.ship.type))])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("span", [_vm._v(_vm._s(_vm.t("views.ship.size")))]),
                    _vm._v(" "),
                    _c("ui-icon", { attrs: { type: "arrow", color: "dark" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.ship.size))])
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-Ship__section__container" }, [
            _c("section", { staticClass: "c-Ship__section" }, [
              _c("h3", { staticClass: "c-Ship__section__heading t-h3" }, [
                _vm._v(_vm._s(_vm.t("views.common.ship_history")))
              ]),
              _vm._v(" "),
              _c("div", {
                ref: "crossContainerOdd",
                staticClass: "c-Ship__section__icon"
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "c-Ship__section__content",
                domProps: { innerHTML: _vm._s(_vm.ship.history) }
              })
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "c-Ship__section" }, [
              _c("h3", { staticClass: "c-Ship__section__heading t-h3" }, [
                _vm._v(_vm._s(_vm.t("views.common.ship_highlight")))
              ]),
              _vm._v(" "),
              _c("div", {
                ref: "crossContainerEven",
                staticClass: "c-Ship__section__icon"
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "c-Ship__section__content",
                domProps: { innerHTML: _vm._s(_vm.ship.highlight) }
              })
            ])
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "c-Ship__close-panel",
        on: { click: _vm.closeAll }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-Ship__line" }, [
      _c("div", { staticClass: "c-Ship__line__inner" }, [
        _c("div", { staticClass: "c-Ship__line__masked" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }